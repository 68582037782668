import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]

  connect() {
    $(this.element).fadeTo(1000, 1, function() {
      const $that = $(this);
      setTimeout(function() {
        $that.fadeOut(1000, function() {
          $that.remove();
        });
      }, 3500);
    });
  }

  remove() {
    $(this.element).fadeOut(250, function() {
      $(this).remove();
    });
  }
}