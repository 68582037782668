import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]

  hide() {
    $(this.element).addClass("hide-away")
  }

  show() {
    $(this.element).removeClass("hide-away")
  }

  get navController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("client-nav"), 
      "client-side--nav"
    )
  } 
}
