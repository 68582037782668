// Courtesy of https://stackoverflow.com/a/53058574

// Drop handler function to get all files
export async function getAllFilesFromEvent(e) {
  let fileEntries = []

  if(e.dataTransfer.items) {
    // Use BFS to traverse entire directory/file structure
    let queue = []
    // Unfortunately e.dataTransfer.items is not iterable i.e. no forEach
    for (let i = 0; i < e.dataTransfer.items.length; i++) {
      const entry = e.dataTransfer.items[i].webkitGetAsEntry()

      // If this is text or a non-file, non-directory being dropped on the page,
      // entry should be null.
      if(entry) {
        queue.push(e.dataTransfer.items[i].webkitGetAsEntry())
      }
    }
    while (queue.length > 0) {
      let entry = queue.shift()

      if (entry.isFile) {
        const file = await getFileFromFileEntry(entry)
        fileEntries.push(file)
      } else if (entry.isDirectory) {
        queue.push(...await readAllDirectoryEntries(entry.createReader()))
      }
    }
  } else {
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      fileEntries.push(e.dataTransfer.files[i])
    }
  }

  return fileEntries;
}

async function getFileFromFileEntry(fileEntry) {
  try {
    return await new Promise(function(resolve, reject) { 
      fileEntry.file(resolve, reject)
    })
  } catch (err) {
    console.log(err)
  }
}

// Get all the entries (files or sub-directories) in a directory 
// by calling readEntries until it returns empty array
async function readAllDirectoryEntries(directoryReader) {
  let entries = []
  let readEntries = await readEntriesPromise(directoryReader)
  while (readEntries.length > 0) {
    entries.push(...readEntries)
    readEntries = await readEntriesPromise(directoryReader)
  }
  return entries
}

// Wrap readEntries in a promise to make working with readEntries easier
// readEntries will return only some of the entries in a directory
// e.g. Chrome returns at most 100 entries at a time
async function readEntriesPromise(directoryReader) {
  try {
    return await new Promise((resolve, reject) => {
      directoryReader.readEntries(resolve, reject)
    });
  } catch (err) {
    console.log(err)
  }
}

// File can be FileEntry or File
export function getExtension(file) {
  return `.${file.name.split(".").pop().toLowerCase()}`
}

// File can be FileEntry or File
export function getMimeType(file) {
  switch(getExtension(file)) {
    case ".wav": {
      return "audio/wav"
    }
    case ".mp3": {
      return "audio/mpeg"
    }
    case ".flac": {
      return "audio/flac"
    }
    case ".jpg": {
      return "image/jpeg"
    }
    case ".jpeg": {
      return "image/jpeg"
    }
    case ".pdf": {
      return "application/pdf"
    }
    case ".png": {
      return "image/png"
    }
    case ".zip": {
      return "application/zip"
    }
  }

  return null
}

export async function readFileToArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    reader.onload = () => {
      const result = reader.result

      // Convert to array buffer
      const bytes = new Uint8Array(result)
      resolve(bytes)
    }
  })
}

export function setupHiddenFileInput({
  addFile,
  allowedFileExtensions,
  containerElement,
}) {
  const hiddenFileInput = document.createElement("input")
  hiddenFileInput.setAttribute("type", "file")
  hiddenFileInput.setAttribute("multiple", "multiple")
  hiddenFileInput.setAttribute("accept", allowedFileExtensions.join(","))
  hiddenFileInput.className = "hiddenFileInput"
  hiddenFileInput.style.visibility = "hidden"
  hiddenFileInput.style.position = "absolute"
  hiddenFileInput.style.top = 0
  hiddenFileInput.style.left = 0
  hiddenFileInput.style.height = 0
  hiddenFileInput.style.width = 0

  if(containerElement.querySelector(".hiddenFileInput")) {
    containerElement.querySelector(".hiddenFileInput").remove()
  }
  containerElement.append(hiddenFileInput)

  hiddenFileInput.addEventListener("change", () => {
    let { files } = hiddenFileInput

    if(files.length) {
      Array.from(files).forEach(file => {
        addFile(file)
      })
    }

    setupHiddenFileInput({
      addFile,
      allowedFileExtensions,
      containerElement,
    })
  })
}