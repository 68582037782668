import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]

  connect() {
    const options = JSON.parse(this.data.get("options") || {})
    $(this.element).maskMoney(options);

    // When an initial value exists
    if(this.element.value) {
      $(this.element).maskMoney("mask");
    }
  }
}