import { getAllFilesFromEvent, getExtension, readFileToArrayBuffer } from "utils/file"
import { showInvalidFileExtensionsModal, showMaxFileSizeModal, showMonthlyUploadLimitReachedModal, showTotalStorageLimitReachedModal } from "utils/modal"
import MultipartFileUploader from "multipart_file_uploader"

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker

export default class FileSystemObjectUploader {
  constructor(opts) {
    this.allowedFileExtensions = opts.allowedFileExtensions
    this.monthlyUploadLimitReached = false
    this.parentId = null
    this.playlistId = null
    this.projectId = null
    this.totalStorageLimitReached = false
    this.uploader = new MultipartFileUploader({
      allowedFileExtensions: this.allowedFileExtensions,
      baseUrl: opts.baseUrl,
      maxFileSize: {
        ".flac": 367001600, // 350 MB
        ".pdf": 524288000, // 500 MB
      },
    })

    this.uploader.on("add:error", (type, data) => { 
      if(type == "invalidFileExtensions") {
        showInvalidFileExtensionsModal(this.allowedFileExtensions, data.invalidFileExtensions) 
      } else if (type == "maxFileSize") {
        const invalidExtensions = [...data.invalidFiles.map(file => getExtension(file))]
        const headerMessages = []
        const bodyMessages = []

        if (invalidExtensions.includes(".flac")) {
          headerMessages.push("For large audio files, please use WAV or MP3.")
          bodyMessages.push("FLAC files must be under 350 MB in order to display waveforms properly.")
          showMaxFileSizeModal("For large audio files, please use WAV or MP3.", "FLAC files must be under 350 MB in order to display waveforms properly.")
        } else if (invalidExtensions.includes(".pdf")) {
          headerMessages.push("PDF files must be under 500 MB.")
          bodyMessages.push("PDF files must be under 500 MB in order to display each individual page properly.")
        }

        showMaxFileSizeModal(headerMessages.join(""), bodyMessages.join(""))
      }
    })
    this.uploader.on("progress", this._updateOverallProgress)
    this.uploader.on("upload:progress", (upload) => this._updateUploadProgress(upload))
    this.uploader.on("upload:completed", (upload) => {
      this._updateUploadStatus(upload, "Successfully uploaded!", "completed")
    })
    this.uploader.on("upload:error", (upload, error) => {
      this._updateUploadStatus(upload, error, "error")
    })
  }

  addFile = (file) => this.addFiles([file])

  addFiles = (files) => {
    if(this.monthlyUploadLimitReached) {
      showMonthlyUploadLimitReachedModal()
      return
    }

    if(this.totalStorageLimitReached) {
      showTotalStorageLimitReachedModal()
      return
    }

    this.uploader.addFiles(files, {
      parent_id: this.parentId,
      playlist_id: this.playlistId,
      project_id: this.projectId
    }, (file) => new Promise(async (resolve, reject) => {
      if(getExtension(file) === ".pdf") {
        const bytes = await readFileToArrayBuffer(file)
        const pdfDocument = await pdfjsLib.getDocument(bytes).promise
        resolve({
          pages: pdfDocument.numPages
        })
      } else {
        resolve({})
      }
    }))
  }

  disableDragNDrop = () => {
    $(window).off(".fileSystemObjectUploader")
    $(window).on("dragover.fileSystemObjectUploader drop.fileSystemObjectUploader", (e) => {
      e.preventDefault();
      return false;
    })
  }

  enableDragNDrop = () => {
    $(window).off(".fileSystemObjectUploader")
    $(window).on("drop.fileSystemObjectUploader", async (e) => {
      e.preventDefault()
      let files = await getAllFilesFromEvent(e.originalEvent)
      this.addFiles(files)
      $("body").removeClass("dragover")
    })
    $(window).on("dragover.fileSystemObjectUploader dragenter.fileSystemObjectUploader", (e) => {
      e.preventDefault()
      $("body").addClass("dragover")
    })
    $(window).on("dragleave.fileSystemObjectUploader", (e) => {
      e.preventDefault()
      $("body").removeClass("dragover")
    })
  }

  toggleFileList = () => {
    $("#progress-card .files-container").slideToggle()
    $("#progress-card .overall-progress-wrapper .fas").toggleClass("fa-chevron-up").toggleClass("fa-chevron-down")
  }

  _createUploadProgressContainer = (upload) => {
    const progress = upload.progress
    const $uploadProgressContainer = $(`
      <div class="progress-container" id="progress-container-${upload.id}">
        <div class="progress-wrapper pt-2">
          <div class="progress-info">
            <div class="progress-label">
              <span>${upload.name}</span>
            </div>
            <div class="progress-percentage">
              <span>${progress == 0 ? "Pending..." : `${progress.toFixed(2)}%`}</span>
            </div>
          </div>
          <div class="progress mb-2">
            <div style="width: ${progress}%;" class="progress-bar bg-default" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
        <hr class="mt-2 mb-0" />
      </div>
    `)
    $("#progress-card .files-container").append($uploadProgressContainer)
  }
  
  _fadeOutAndRemoveUpload = (uploadId) => {
    const $uploadProgressContainer = $(`#progress-container-${uploadId}`)
    $uploadProgressContainer.fadeOut(400, () => {
      $uploadProgressContainer.remove()
    })
    $uploadProgressContainer.slideUp(400)
  }

  _removeFile = (uploadId) => {
    this._fadeOutAndRemoveUpload(uploadId)
    this.uploader.removeFile(uploadId)
  }

  _updateUploadStatus = (upload, message, status) => {
    let $uploadProgressContainer = $(`#progress-container-${upload.id}`)

    if($uploadProgressContainer.length === 0) {
      this._createUploadProgressContainer(upload)
      $uploadProgressContainer = $(`#progress-container-${upload.id}`)
    }
    $uploadProgressContainer.find(".progress-label span").text(message)

    switch(status) {
      case "completed": {
        $uploadProgressContainer.find(".progress-bar").removeClass("bg-default").addClass("bg-success")
        setTimeout(() => { 
          this._fadeOutAndRemoveUpload(upload.id)
        }, 2000)
        break
      }
      case "error": {
        $uploadProgressContainer.find(".progress-bar").removeClass("bg-default").addClass("bg-danger")
        $uploadProgressContainer.find(".progress-label span").addClass("bg-danger text-white")
        $uploadProgressContainer.find(".progress-percentage span").html(`
          <i class="fas fa-trash text-dark mr-2"></i>
          <i class="fas fa-redo text-dark"></i>
        `)
        $uploadProgressContainer.find(".fa-trash").one("click", () => {
          this._removeFile(upload.id)
        })
        $uploadProgressContainer.find(".fa-redo").one("click", () => {
          $uploadProgressContainer.find(".progress-label span").text(upload.name)
          $uploadProgressContainer.find(".progress-bar").removeClass("bg-danger").addClass("bg-default")
          $uploadProgressContainer.find(".progress-label span").removeClass("bg-danger text-white")
          $uploadProgressContainer.find(".progress-percentage span").html("Restart pending...")
          upload.restart()
        })
        break
      }
      default: {
        break
      }
    }
  }

  _updateUploadProgress = (upload) => {
    const $progressCard = $("#progress-card")
    let $uploadProgressContainer = $(`#progress-container-${upload.id}`)
    const progress = upload.progress

    if($uploadProgressContainer.length) {
      $uploadProgressContainer.find(".progress-percentage span").text(progress == 0 ? "Pending..." : `${progress.toFixed(2)}%`)
      $uploadProgressContainer.find(".progress-bar").css("width", `${progress}%`)
    } else {
      this._createUploadProgressContainer(upload)
    }
  }

  _updateOverallProgress = () => {
    const $progressCard = $("#progress-card")
    const addedSize = this.uploader.addedSize
    const completedSize = this.uploader.completedSize
    const progress = this.uploader.progress

    $progressCard.addClass("show")
    $progressCard.find(".overall-progress-wrapper .progress-label span").text(`${completedSize}/${addedSize} files completed`)
    $progressCard.find(".overall-progress-wrapper .progress-percentage span").text(`${progress.toFixed(2)}%`)
    $progressCard.find(".overall-progress-wrapper .progress-bar").css("width", `${progress}%`)

    if(addedSize === 0) {
      this.toggleFileList()
      $("#progress-card").removeClass("show")
    }

    if(completedSize === addedSize && addedSize !== 0) {
      window.location.reload(true)
    }
  }
}