import debounce from "lodash/debounce"
import Decimal from "decimal.js-light"
import EventEmitter from "events"
import { fabric } from "fabric"
import Pickr from "@simonwep/pickr"
import { ResizeObserver } from "@juggle/resize-observer"
import { capitalizeFirstLetter } from "utils/formatting"

import "@simonwep/pickr/dist/themes/nano.min.css"

fabric.Bubble = fabric.util.createClass(fabric.Circle, {
  type: "bubble",
})

// jQuery and bootstrap dependency

export default class ImageAnnotator extends EventEmitter {
  constructor(opts) {
    super()

    this.container = opts.container
    this.id = opts.id
    this.newCommentPopoverContent = opts.newCommentPopoverContent
    this.comments = {}
    this.enablePhotoswipe = opts.enablePhotoswipe
    this.disableAnnotating = opts.disableAnnotating === true

    this._annotationType = "rect" // Used for setting the type of annotation: ellipse, rect
    this._bubbleRadius = 10 // Size of bubbles
    this._canvas = null // DOM canvas element
    this._fabricCanvas = null // fabricJS canvas element
    this._popoverOpen = false // Used for marker popovers
    this._resizeObserver = new ResizeObserver(this._handleResize)
    this._selectedObject = null // Used for drawing
    this._sharedOptions = {
      angle: 0,
      borderColor: "rgba(23, 43, 77, 0.6)",
      cornerColor: "rgba(23, 43, 77, 1)",
      cornerSize: 12,
      cornerStrokeColor: "#ffffff",
      fill: "rgba(0, 0, 0, 0)",
      hasRotatingPoint: false,
      lockRotation: true,
      noScaleCache: false,
      perPixelTargetFind: true,
      objectCaching: true,
      stroke: "rgba(23, 43, 77, 1)",
      strokeWidth: 3,
      strokeUniform: true,
      transparentCorners: false,
    }
    this._startingPointerX = null // Used for dragging
    this._startingPointerY = null // Used for dragging
  }

  addComment = ({
    id,
    addressed,
    authorInitials,
    popoverContent,
    canvasObject,
  }) => {
    const self = this

    self.comments[id] = {
      beingDragged: false,
      id,
      addressed,
      authorInitials,
      popoverContent,
      canvasObject,
    }

    if(self._fabricCanvas) {
      self._drawComment(id)
    }
  }

  destroy = () => this._resizeObserver.disconnect()

  draw = () => {
    const self = this

    self._canvas = document.createElement("canvas")
    self._canvas.id = `canvas-${self.id}`
    self._canvas.style.display = "block"
    self._canvas.style.width = "100%"
    self._canvas.style.height = "100%"

    $(self.container).append(self._canvas)
    self._fabricCanvas = new fabric.Canvas(self._canvas.id, {
      hoverCursor: "pointer",
      selectionColor: "rgba(0, 0, 0, 0)",
      selectionBorderColor: "rgba(0, 0, 0, 0)",
      selectionLineWidth: self._sharedOptions.strokeWidth,
      uniScaleTransform: true,
    })

    self._setCanvasDimensions()

    self._resizeObserver.observe(self.container)

    self._fabricCanvas.on("mouse:down", self._handleMousedown)
    self._fabricCanvas.on("mouse:move", self._handleMousemove)
    self._fabricCanvas.on("mouse:up", self._handleMouseup)
    self._fabricCanvas.on("mouse:out", self._handleMouseout)

    if(!self.disableAnnotating) {
      self._addAnnotationTool()
    }

    Object.keys(self.comments).forEach(id => self._drawComment(id))
  }

  removeComment = id => {
    const self = this
    self._removeCommentObjects(id)
    delete self.comments[id]
  }

  _addAnnotationTool = () => {
    const self = this

    const div = document.createElement("div")
    div.id = "image-annotator-annotation-type-selector"

    const annotationTypeObjects = [
      { type: "ellipse", icon: "far fa-circle" },
      { type: "rect", icon: "far fa-square" },
    ]
    const buttonSelector = "#image-annotator-annotation-type-selector > button:not(.color-picker)"

    annotationTypeObjects.forEach(annotationTypeObject => {
      const btn = document.createElement("button")
      btn.style.color = annotationTypeObject.type === self._annotationType ? self._sharedOptions.stroke : null
      btn.setAttribute("data-type", annotationTypeObject.type)
      btn.innerHTML = `
        <i class="${annotationTypeObject.icon}"></i>
      `
      btn.addEventListener("click", (e) => {
        self._annotationType = annotationTypeObject.type
        document.querySelectorAll(buttonSelector).forEach(_btn => _btn.style.color = null)
        btn.style.color = self._sharedOptions.stroke
      })
      div.append(btn)
    })

    const colorPicker = document.createElement("button")
    colorPicker.className = "color-picker"
    colorPicker.innerHTML = `
      <i class="far fa-eye-dropper"></i>
    `
    div.append(colorPicker)
    const pickr = Pickr.create(self._pickrSettings(colorPicker))
    pickr.on("change", (color, instance) => {
      const rgba = color.toRGBA()
      const clonedHSVa = color.clone()
      clonedHSVa.a = 0.6
      const lightenedRGBA = clonedHSVa.toRGBA()
      self._sharedOptions.stroke = rgba.toString()
      self._sharedOptions.borderColor = lightenedRGBA.toString()
      self._sharedOptions.cornerColor = self._sharedOptions.stroke

      document.querySelectorAll(buttonSelector).forEach(btn => {
        btn.style.color = btn.getAttribute("data-type") === self._annotationType
          ? self._sharedOptions.stroke 
          : null
      })

      const activeObject = self._fabricCanvas.getActiveObject()
      if(activeObject) {
        activeObject.set(self._sharedOptions)
        self._fabricCanvas.renderAll()
      }

      instance.applyColor()
    })

    if(self.enablePhotoswipe) {
      const btn = document.createElement("button")
      btn.innerHTML = `
        <i class="far fa-expand-arrows"></i>
      `
      btn.setAttribute("data-action", "photoswipe#open")
      div.append(btn)
    }

    self.container.append(div)
  }

  _addOrUpdateActions = (e, object) => {
    const self = this
    const pointer = self._fabricCanvas.getPointer(e)
    let width, height, coords, top, left, xLeft, yBottom

    const setVariables = () => {
      width = object.getScaledWidth()
      height = object.getScaledHeight()
      coords = object.calcCoords()
      top = coords.tl.y
      left = coords.tl.x
      xLeft = new Decimal(width).dividedBy(2).plus(left).toNumber()
      yBottom = new Decimal(height).dividedBy(2).plus(top).toNumber()
    }
    setVariables()

    const leftPct = new Decimal(xLeft).dividedBy(self._canvas.clientWidth).toNumber()
    const topPct = new Decimal(height).dividedBy(2).plus(yBottom).dividedBy(self._canvas.clientHeight).toNumber()

    let addDiv = false
    const divId = "image-annotator-annotation-actions"
    let div = document.getElementById(divId)

    if(div == null) {
      addDiv = true
      div = document.createElement("div")
      const cancelBtn = document.createElement("button")
      cancelBtn.className = "action cancel"
      cancelBtn.innerHTML = `
        <i class="fas fa-times"></i>
      `
      cancelBtn.addEventListener("click", () => {
        self._removeUnsavedAnnotation()
      })
      const confirmBtn = document.createElement("button")
      confirmBtn.className = "action confirm"
      confirmBtn.innerHTML = `
        <i class="fas fa-check"></i>
      `
      confirmBtn.addEventListener("click", () => {
        div.remove()
        self._fabricCanvas.discardActiveObject()
        self._fabricCanvas.renderAll()
        setVariables()
        self._drawCommentForm(xLeft, yBottom, object)
      })
      div.id = "image-annotator-annotation-actions"
      div.append(cancelBtn)
      div.append(confirmBtn)
    }

    div.style.top = `${topPct * 100}%`
    div.style.left = `${leftPct * 100}%`

    if(addDiv) {
      self.container.append(div)
      const halfClientWidth = new Decimal(div.clientWidth).dividedBy(2).toNumber()
      div.style.marginLeft = `-${halfClientWidth}px`
    }
  }

  /*
   * x - REQUIRED. x coordinate for marker, relative to canvas
   * y - REQUIRED. y coordinate for marker, relative to canvas
   * object - REQUIRED. Canvas object to pass along to marker:add event
   */
  _drawCommentForm = (x, y, object) => {
    const self = this

    const $marker = self._placeMarker(x, y)
    $marker.attr("data-toggle", "popover")
    $marker.attr("data-html", true)
    $marker.attr("data-placement", "auto")
    $marker.attr("data-trigger", "manual")
    $marker.attr("data-content", self.newCommentPopoverContent)

    const remove = () => {
      $marker.removeClass("show")
      $(document).off(".annotatorMenu")
      self._popoverOpen = false
      self._removeUnsavedAnnotation(150)
    }

    $marker.popover().on("shown.bs.popover", () => {
      const $popover = $(`#${$marker.attr("aria-describedby")}`)
      $popover.find("[data-dismiss='popover']").on("click", () => $marker.popover("hide"))
      $popover.find("[autofocus]").focus()
      self.emit("marker:add", {
        canvasObject: JSON.stringify(self._objectToJSON(object)),
        popover: $popover[0]
      })

      $(document).on("click.annotatorMenu", e => {
        if(!$(e.target).hasClass(".popover") && $(".popover").has(e.target).length === 0) {
          $marker.popover("hide")
        }
      })
    }).on("hide.bs.popover", () => {
      remove()
    }).on("hidden.bs.popover", () => {
      $marker.remove()
    }).popover("show")

    self._popoverOpen = true
  }

  _bubbleOptions = (e) => {
    const self = this
    const pointer = self._fabricCanvas.getPointer(e)
    let originX, originY

    originX = "left"
    originY = "top"

    const radius = self._bubbleRadius
    const halfBubbleStrokeWidth = new Decimal(self._sharedOptions.strokeWidth).dividedBy(2)
    const top = new Decimal(pointer.y).minus(radius).minus(halfBubbleStrokeWidth).minus(0.5).toNumber() // No idea why subtracting 0.5 works but it does
    const left = new Decimal(pointer.x).minus(radius).minus(halfBubbleStrokeWidth).minus(0.5).toNumber()
    
    return {
      ...self._sharedOptions,
      fill: self._sharedOptions.stroke,
      top,
      left,
      radius,
      originX,
      originY,
    }
  }

  _circleOptions = (e) => {
    const self = this
    const pointer = self._fabricCanvas.getPointer(e)
    let originX, originY, pointerXStart, pointerYStart

    if(self._startingPointerX < pointer.x) {
      originX = "left"
      pointerXStart = self._startingPointerX
    } else {
      originX = "right"
      pointerXStart = pointer.x
    }
    
    if(self._startingPointerY < pointer.y) {
      originY = "top"
      pointerYStart = self._startingPointerY
    } else {
      originY = "bottom"
      pointerYStart = pointer.y
    }

    const halfCircleStrokeWidth = self._sharedOptions.strokeWidth / 2
    const top = self._startingPointerY - (halfCircleStrokeWidth - 0.5) // No idea why subtracting 0.5 works but it does
    const left = self._startingPointerX - (halfCircleStrokeWidth - 0.5)

    let radius = Math.max(Math.abs(self._startingPointerY - pointer.y), Math.abs(self._startingPointerX - pointer.x)) / 2

    if(radius <= halfCircleStrokeWidth) {
      radius = halfCircleStrokeWidth
    }

    return {
      ...self._sharedOptions,
      top,
      left,
      radius,
      originX,
      originY,
    }
  }

  _bubbleOptionsForAnnotationObject = (object) => {
    const self = this
    const originX = object.originX || "left"
    const originY = object.originY || "top"
    let left, top
    
    const halfWidth = new Decimal(object.getScaledWidth()).dividedBy(2)
    const halfStrokeWidth = new Decimal(self._sharedOptions.strokeWidth).dividedBy(2)
    if(originX === "left") {
      const middle = new Decimal(object.left).plus(halfWidth)
      left = middle.minus(self._bubbleRadius).minus(halfStrokeWidth).minus(0.5).toNumber()
    } else {
      const middle = new Decimal(object.left).minus(halfWidth)
      left = middle.plus(self._bubbleRadius).plus(halfStrokeWidth).plus(0.5).toNumber()
    } 

    if(originY === "top") {
      const bottom = new Decimal(object.top).plus(object.getScaledHeight())
      top = bottom.minus(self._bubbleRadius).minus(halfStrokeWidth).minus(0.5).toNumber()
    } else {
      const bottom = new Decimal(object.top)
      top = bottom.plus(self._bubbleRadius).plus(halfStrokeWidth).plus(0.5).toNumber()
    }

    return {
      ...self._sharedOptions,
      hoverCursor: "default",
      id: object.id,
      fill: object.stroke,
      stroke: object.stroke,
      visible: object.visible,
      radius: self._bubbleRadius,
      originX,
      originY,
      left,
      top,
    }
  }

  _ellipseOptions = (e) => {
    const self = this
    const pointer = self._fabricCanvas.getPointer(e)
    let originX, originY

    if(self._startingPointerX < pointer.x) {
      originX = "left"
    } else {
      originX = "right"
    }
    
    if(self._startingPointerY < pointer.y) {
      originY = "top"
    } else {
      originY = "bottom"
    }

    const halfEllipseStrokeWidth = self._sharedOptions.strokeWidth / 2
    const top = self._startingPointerY - (halfEllipseStrokeWidth - 0.5) // No idea why subtracting 0.5 works but it does
    const left = self._startingPointerX - (halfEllipseStrokeWidth - 0.5)
    let rx = Math.abs(self._startingPointerX - pointer.x) / 2
    let ry = Math.abs(self._startingPointerY - pointer.y) / 2

    if (rx > self._sharedOptions.strokeWidth) {
      rx -= halfEllipseStrokeWidth
      rx = rx < 0 ? 0 : rx
    }
    if (ry > self._sharedOptions.strokeWidth) {
      ry -= halfEllipseStrokeWidth
      ry = ry < 0 ? 0 : ry
    }

    return {
      ...self._sharedOptions,
      top,
      left,
      rx,
      ry,
      originX,
      originY,
    }
  }

  _setObjectsSelectable = selectable => {
    const self = this

    self._fabricCanvas.getObjects().forEach(object => {
      object.set({
        selectable
      })
    })
  }

  _createAnnotationObjectFromEvent = (e, type) => {
    const self = this
    const object = fabric[capitalizeFirstLetter(type)]
    const options = self[`_${type}Options`](e)
    return new object(options)
  }

  _drawComment = id => {
    const self = this
    const comment = self.comments[id]
    const commentMarkerId = `comment-marker-${id}`
    self._removeCommentObjects(id)
    
    const currentWidth = self._fabricCanvas.getWidth()
    const resizeRatio = new Decimal(currentWidth).dividedBy(comment.canvasObject.canvasWidth).toNumber()
    const fabricObject = fabric[capitalizeFirstLetter(comment.canvasObject.type)]
    const object = new fabricObject({
      ...self._sharedOptions,
      ...comment.canvasObject
    })
    let left, top, scaleX, scaleY, markerPosLeft, markerPosTop

    if(object.type === "bubble") {
      const halfBubbleStrokeWidth = new Decimal(self._sharedOptions.strokeWidth).dividedBy(2)
      left = new Decimal(object.left).plus(object.radius).plus(halfBubbleStrokeWidth).plus(0.5).times(resizeRatio).minus(self._bubbleRadius).minus(halfBubbleStrokeWidth).minus(0.5).toNumber()
      top = new Decimal(object.top).plus(object.radius).plus(halfBubbleStrokeWidth).plus(0.5).times(resizeRatio).minus(self._bubbleRadius).minus(halfBubbleStrokeWidth).minus(0.5).toNumber()
      scaleX = object.scaleX
      scaleY = object.scaleY
    } else {
      left = new Decimal(object.left).times(resizeRatio).toNumber()
      top = new Decimal(object.top).times(resizeRatio).toNumber()
      scaleX = new Decimal(object.scaleX).times(resizeRatio).toNumber()
      scaleY = new Decimal(object.scaleY).times(resizeRatio).toNumber()
    }

    object.set({
      id,
      left,
      top,
      scaleX,
      scaleY,
      visible: false,
      hoverCursor: "default",
    })

    if(object.type === "bubble") {
      // Override radius with our own sizing
      object.set({
        radius: self._bubbleRadius
      })
    }

    self._fabricCanvas.add(object)
    const originX = object.originX || "left"
    const originY = object.originY || "top"
    const halfWidth = new Decimal(object.getScaledWidth()).dividedBy(2)
    const halfHeight = new Decimal(object.getScaledHeight()).dividedBy(2)

    if(originX === "left") {
      markerPosLeft = new Decimal(object.left).plus(halfWidth).toNumber()
    } else {
      markerPosLeft = new Decimal(object.left).minus(halfWidth).toNumber()
    } 

    if(originY === "top") {
      markerPosTop = new Decimal(object.top).plus(halfHeight).toNumber()
    } else {
      markerPosTop = new Decimal(object.top).minus(halfHeight).toNumber()
    }
    
    const $marker = self._placeMarker(markerPosLeft, markerPosTop)
    $marker.addClass(commentMarkerId)

    const setVisible = visible => {
      object.set({
        visible: visible
      })
      self._fabricCanvas.renderAll()
    }

    $marker
      .on("comment:show", () => setVisible(true))
      .on("comment:hide", () => setVisible(false))

    self._setObjectsSelectable(false)
    self._fabricCanvas.renderAll()
  }

  _placeMarker = (x, y) => {
      const self = this
      const leftPct = x / self._canvas.clientWidth
      const topPct = y / self._canvas.clientHeight
      const halfStrokeWidth = new Decimal(this._sharedOptions.strokeWidth).dividedBy(2).toNumber()

      const marker = document.createElement("div")
      marker.style.position = "absolute"
      marker.style.left = `${leftPct * 100}%`
      marker.style.top = `${topPct * 100}%`
      marker.style.zIndex = 10

      if(self.disableCommentBubbles) {
        marker.style.width = "1px"
        marker.style.height = "1px"
        marker.style.marginLeft = "-0.5px"
        marker.style.marginTop = "-0.5px"
      } else {
        marker.style.width = `${new Decimal(this._bubbleRadius).times(2).plus(this._sharedOptions.strokeWidth).toNumber()}px`
        marker.style.height = `${new Decimal(this._bubbleRadius).times(2).plus(this._sharedOptions.strokeWidth).toNumber()}px`
        marker.style.marginLeft = `-${new Decimal(this._bubbleRadius).plus(halfStrokeWidth).minus(0.5).toNumber()}px`
        marker.style.marginTop = `-${new Decimal(this._bubbleRadius).plus(halfStrokeWidth).minus(0.5).toNumber()}px`
      }
      marker.className = "image-annotator-marker comment-marker fade"
      const $marker = $(marker)
      $(self.container).append($marker)
      $marker.addClass("show")

      return $marker
    }

  _handleObjectMoving = (options) => {
    const self = this
    const e = options.e

    self._addOrUpdateActions(e, options.target)
  }

  _handleObjectScaling = (options) => {
    const self = this
    const e = options.e

    self._addOrUpdateActions(e, options.target)
  }

  _rectOptions = (e) => {
    const self = this
    const pointer = self._fabricCanvas.getPointer(e)
    let pointerXStart, pointerXEnd, pointerYStart, pointerYEnd

    if(self._startingPointerX < pointer.x) {
      pointerXStart = self._startingPointerX
      pointerXEnd = pointer.x
    } else {
      pointerXStart = pointer.x
      pointerXEnd = self._startingPointerX
    }
    
    if(self._startingPointerY < pointer.y) {
      pointerYStart = self._startingPointerY
      pointerYEnd = pointer.y
    } else {
      pointerYStart = pointer.y
      pointerYEnd = self._startingPointerY
    }

    const halfRectStrokeWidth = self._sharedOptions.strokeWidth / 2
    const top = pointerYStart - (halfRectStrokeWidth - 0.5) // No idea why subtracting 0.5 works but it does
    const left = pointerXStart - (halfRectStrokeWidth - 0.5)
    let width = pointerXEnd - pointerXStart
    let height = pointerYEnd - pointerYStart

    if(width <= self._sharedOptions.strokeWidth) {
      width = self._sharedOptions.strokeWidth
    }

    if(height <= self._sharedOptions.strokeWidth) {
      height = self._sharedOptions.strokeWidth
    }

    return {
      ...self._sharedOptions,
      top,
      left,
      width,
      height,
      rx: 5,
      ry: 5,
      hasBorders: false,
    }
  }

  _removeUnsavedAnnotation = (fadeOutDuration) => {
    const self = this
    const actions = document.getElementById("image-annotator-annotation-actions")

    if(actions) {
      actions.remove()
    }

    self._fabricCanvas.getObjects().forEach(object => {
      if(object.id == null) {
        if(fadeOutDuration) {
          object.animate("opacity", 0, {
            duration: fadeOutDuration,
            onChange: () => self._fabricCanvas.renderAll(),
            onComplete: () => self._fabricCanvas.remove(object),
          })
        } else {
          self._fabricCanvas.remove(object)
        }
      }
    })
  }

  _handleClick = e => {
    const self = this
    const pointer = self._fabricCanvas.getPointer(e)

    const object = self._createAnnotationObjectFromEvent(e, "bubble")
    self._fabricCanvas.add(object)
    self._setObjectsSelectable(false)
    self._fabricCanvas.renderAll()

    self._drawCommentForm(pointer.x, pointer.y, object)
  }

  _handleMousedown = options => {
    const self = this
    let e = options.e

    if(e.touches) {
      // Ignore multi-touch events
      if(e.touches.length > 1) {
        return;
      }

      e = self._createSimulatedMouseEvent(e, "mousedown")
    }

    if(self._popoverOpen || (options.target && options.target.id == null) || self.disableAnnotating) {
      return
    }

    self._removeUnsavedAnnotation()

    const pointer = self._fabricCanvas.getPointer(e)

    self._startingPointerX = pointer.x
    self._startingPointerY = pointer.y
    self._selectedObject = self._createAnnotationObjectFromEvent(e, self._annotationType)
    self._selectedObject.set({
      visible: false
    })
    self._fabricCanvas.add(self._selectedObject)
    self._setObjectsSelectable(false)
    self._fabricCanvas.renderAll()
  }

  _handleMouseout = options => {
    const self = this
    let e = options.e

    if(e.touches) {
      // Ignore multi-touch events
      if(e.touches.length > 1) {
        return;
      }

      e = self._createSimulatedMouseEvent(e, "mouseleave")
    }

    if(self._popoverOpen || (options.target && options.target != self._selectedObject)) {
      return
    }
    // const id = Object.keys(this.comments).filter(id => this.comments[id].beingDragged)[0]

    // if(id) {
      // this._handleMouseup(e)
    // }
  }

  _handleMousemove = options => {
    const self = this
    let e = options.e

    if(e.touches) {
      // Ignore multi-touch events
      if(e.touches.length > 1) {
        return;
      }

      e = self._createSimulatedMouseEvent(e, "mousemove")
    }

    if(self._popoverOpen || self.disableAnnotating) {
      return
    }

    if(self._selectedObject != null) {
      self._updateSelectedObject(e)
      this._selectedObject.set({
        visible: true
      })
      self._fabricCanvas.renderAll()
    }
  }

  _handleMouseup = options => {
    const self = this
    let e = options.e

    if(e.touches) {
      // Ignore multi-touch events
      if(e.touches.length > 1) {
        return;
      }

      e = self._createSimulatedMouseEvent(e, "mouseup")
    }

    if(self._popoverOpen || self._selectedObject == null || self.disableAnnotating) {
      return
    }

    const pointer = self._fabricCanvas.getPointer(e)
    const dragThreshold = 1
    const wasDrag = self._startingPointerX - dragThreshold > pointer.x || 
      self._startingPointerX + dragThreshold < pointer.x || 
      self._startingPointerY - dragThreshold > pointer.y || 
      self._startingPointerY + dragThreshold < pointer.y

    // If the user dragged more than the threshold, count it as a drag. Otherwise it's a click.
    if(wasDrag) {
      self._updateSelectedObject(e)
      self._selectedObject.set({ selectable: true })
      self._selectedObject.on("moving", self._handleObjectMoving)
      self._selectedObject.on("scaling", self._handleObjectScaling)
    } else {
      self._fabricCanvas.remove(self._selectedObject)
      self._handleClick(e)
    }

    if(wasDrag) {
      self._fabricCanvas.setActiveObject(self._selectedObject)
      self._addOrUpdateActions(e, self._selectedObject)
    }

    self._fabricCanvas.renderAll()
    self._startingPointerX = null
    self._startingPointerY = null
    self._selectedObject = null
  }

  _handleResize = debounce(() => {
    const self = this
    const originalWidth = self._fabricCanvas.getWidth()
    const dimensions = self._setCanvasDimensions()
    const resizeRatio = new Decimal(dimensions.width).dividedBy(originalWidth).toNumber()

    if(resizeRatio == 1) {
      return
    }

    self._fabricCanvas.getObjects().forEach(object => {
      let left, top, scaleX, scaleY

      if(object.type === "bubble") {
        const halfBubbleStrokeWidth = new Decimal(self._sharedOptions.strokeWidth).dividedBy(2)
        left = new Decimal(object.left).plus(self._bubbleRadius).plus(halfBubbleStrokeWidth).plus(0.5).times(resizeRatio).minus(self._bubbleRadius).minus(halfBubbleStrokeWidth).minus(0.5).toNumber()
        top = new Decimal(object.top).plus(self._bubbleRadius).plus(halfBubbleStrokeWidth).plus(0.5).times(resizeRatio).minus(self._bubbleRadius).minus(halfBubbleStrokeWidth).minus(0.5).toNumber()
        scaleX = object.scaleX
        scaleY = object.scaleY
      } else {
        left = new Decimal(object.left).times(resizeRatio).toNumber()
        top = new Decimal(object.top).times(resizeRatio).toNumber()
        scaleX = new Decimal(object.scaleX).times(resizeRatio).toNumber()
        scaleY = new Decimal(object.scaleY).times(resizeRatio).toNumber()
      }

      object.set({
        left,
        top,
        scaleX,
        scaleY,
        strokeUniform: self._sharedOptions.strokeUniform,
      })
      object.setCoords()

      // If this is a non-bubble annotation object, update its bubble's coordinates based on its new properties
      if(object.type !== "bubble") {
        const bubble = self._fabricCanvas.getObjects().find(potentialBubble => potentialBubble.type === "bubble" && potentialBubble.id === object.id)
        if(bubble) {
          bubble.set(self._bubbleOptionsForAnnotationObject(object))
          bubble.setCoords()
        }
      }
    })
    
    self._fabricCanvas.renderAll()
  }, 100, {
    leading: true,
    trailing: true
  })

  _hideObjectMarkerPopover = object => {
    const self = this
    const commentMarkers = document.querySelectorAll(`.comment-marker-${object.id}`)

    if(commentMarkers.length) {
      commentMarkers.forEach(commentMarker => {
        $(commentMarker).popover("hide")
      })
    }
  }

  _removeCommentObjects = id => {
    const self = this
    const commentMarkers = document.querySelectorAll(`.comment-marker-${id}`)

    if(commentMarkers.length) {
      commentMarkers.forEach(commentMarker => commentMarker.remove())
    }

    if(self._fabricCanvas) {
      self._fabricCanvas.getObjects().forEach(object => {
        const obj = object.toObject(["id"])
        if(object.id == id) {
          self._fabricCanvas.remove(object)
        }
      })
    }
  }

  _setCanvasDimensions() {
    const self = this

    if(self._fabricCanvas) {
      const height = self.container.clientHeight
      const width = self.container.clientWidth

      self._fabricCanvas.setHeight(height)
      self._fabricCanvas.setWidth(width)

      return {
        height,
        width
      }
    }
  }

  _createSimulatedMouseEvent(e, simulatedType) {

    var touch = e.changedTouches[0],
        simulatedEvent = document.createEvent("MouseEvents");
    
    // Initialize the simulated mouse event using the touch event's coordinates
    simulatedEvent.initMouseEvent(
      simulatedType,    // type
      true,             // bubbles                    
      true,             // cancelable                 
      window,           // view                       
      1,                // detail                     
      touch.screenX,    // screenX                    
      touch.screenY,    // screenY                    
      touch.clientX,    // clientX                    
      touch.clientY,    // clientY                    
      false,            // ctrlKey                    
      false,            // altKey                     
      false,            // shiftKey                   
      false,            // metaKey                    
      0,                // button                     
      null              // relatedTarget              
    );

    return simulatedEvent
  }

  _objectToJSON = (object) => {
    const self = this
    const objectJSON = object.toJSON()

    objectJSON.canvasWidth = self._fabricCanvas.getWidth()
    objectJSON.canvasHeight = self._fabricCanvas.getHeight()
    delete objectJSON.transformMatrix
    
    return objectJSON
  }

  _pickrSettings = (el) => {
    return { // For Pickr instances
      el,
      theme: "nano", // or 'monolith', or 'nano'
      comparison: false,
      components: {

        // Main components
        preview: true,
        opacity: false,
        hue: true,

        // Input / output Options
        interaction: {
          hex: false,
          rgba: false,
          hsla: false,
          hsva: false,
          cmyk: false,
          input: false,
          clear: false,
          save: false,
        }
      },
      default: "rgba(23, 43, 77, 1)",
      swatches: [
        "rgba(23, 43, 77, 1)", // dark blue
        "rgba(94, 114, 228, 1)", // purple
        "rgba(17, 205, 239, 1)", // teal
        "rgba(140, 251, 208, 1)", // moving
        "rgba(255, 214, 0, 1)", // yellow
        "rgba(251, 99, 64, 1)", // orange
        "rgba(245, 54, 92, 1)", // red
      ],
      useAsButton: true
    }
  }

  _updateSelectedObject = e => {
    const self = this
    const options = self[`_${self._selectedObject.type}Options`](e)

    self._selectedObject.set(options)
    self._selectedObject.setCoords()

    window.testObj = JSON.stringify(self._selectedObject)
  }
}