import { Controller } from "stimulus"
import merge from "lodash.merge"
import { setupHiddenFileInput } from "utils/file"
import FileSystemObjectUploader from "file_system_object_uploader"

export default class extends Controller {
  static targets = [ "fileBrowserButton" ]

  connect() {
    const self = this

    if(window.fileSystemObjectUploader == null) {
      window.fileSystemObjectUploader = new FileSystemObjectUploader({
        allowedFileExtensions: self.data.get("allowedFileExtensions").split(","),
        baseUrl: `/s/${publicId()}/uploads/s3/file_system_objects/multipart`
      })
      $(document).on("click", "#progress-card .overall-progress-wrapper", window.fileSystemObjectUploader.toggleFileList)
    }

    window.fileSystemObjectUploader.enableDragNDrop()
    window.fileSystemObjectUploader.monthlyUploadLimitReached = this.data.get("monthlyUploadLimitReached") == "true"
    window.fileSystemObjectUploader.totalStorageLimitReached = this.data.get("totalStorageLimitReached") == "true"
    window.fileSystemObjectUploader.parentId = this.data.get("parentId")
    window.fileSystemObjectUploader.projectId = this.data.get("projectId")

    setupHiddenFileInput({
      addFile: window.fileSystemObjectUploader.addFile,
      allowedFileExtensions: window.fileSystemObjectUploader.allowedFileExtensions,
      containerElement: self.element,
    })
  }

  disconnect() {
    // Only disable page-wide drag-n-drop if there's no other file uploaders on the page
    if($("[data-controller*='file-system-object-uploader']").length === 0) {
      window.fileSystemObjectUploader.disableDragNDrop()
    }
  }

  openFileDialog() {
    if($(this.element).find("input[type='file']").length) {
      $(this.element).find("input[type='file']").click()
    }
  }
} 