import ApplicationController from "controllers/application_controller"
import { showClientUploadsUpsellModal, showCollaborationUpsellModal, showPortfolioPlayerUpsellModal } from "utils/modal"

export default class extends ApplicationController {
  static targets = [ ]

  triggerClientUploadsUpsell(e) {
    e.preventDefault()

    showClientUploadsUpsellModal()
  }

  triggerCollaborationUpsell(e) {
    e.preventDefault()

    showCollaborationUpsellModal()
  }

  triggerPortfolioPlayerUpsell(e) {
    e.preventDefault()

    showPortfolioPlayerUpsellModal(this.data.get("trialAvailable") === "true")
  }
}
