import ApplicationController from "controllers/application_controller"
import { capitalizeFirstLetter } from "utils/formatting" 

export default class extends ApplicationController {
  static targets = [ 
    "addressedCheckboxInput",
    "addressedCheckboxLabel",
    "contentWrapper",
    "showLess",
    "showMore",
    "childComments",
    "childCommentsToggleIcon"
  ]

  connect() {
    const commentId = this.data.get("id")
    const uuid = this.generateUUID()

    if(this.hasAddressedCheckboxInputTarget) {
      $(this.addressedCheckboxInputTarget).attr("id", `comment-checkbox-${commentId}-${uuid}`)
    }

    if(this.hasAddressedCheckboxLabelTarget) {
      $(this.addressedCheckboxLabelTarget).attr("for", `comment-checkbox-${commentId}-${uuid}`)
    }

    if(this.wrapperController) {
      this.wrapperController.updateState()
    }

    $(this.element).find(".dropdown-menu [data-toggle='dropdown']").on("click", () => {
      $(this.element).find(".dropdown-menu [data-toggle='dropdown']").dropdown("hide")
    })

    if(this.data.get("placeMarker") === "true") {
      this._placeMarker()
    }
  }

  disconnect() {
    const commentId = this.data.get("id")
    const fileSystemObjectId = this.data.get("fileSystemObjectId")

    if(this.data.get("placeMarker") === "true") {
      this._removeMarker()
    }
  }

  delete(e) {
    e.preventDefault()
    const self = this
    const element = self.element
    const url = $(e.currentTarget).attr("href")
    const commentId = self.data.get("id")
    const childCommentId = $(e.currentTarget).parents(".child-comment").attr("data-id")
    const isChild = childCommentId != null

    const confirmed = confirm("Are you sure?")

    if(confirmed) {
      $.ajax({
        url: url,
        method: "DELETE",
        headers: {
          "X-CSRF-Token": Rails.csrfToken()
        },
        success: function(html) {
          if(isChild) {
            $(`.comment-list-item-${commentId}`).each(function(index, commentListItemElement) {
              $(commentListItemElement).find(`.child-comment-${childCommentId}`).remove()
              
              if($(commentListItemElement).find(".child-comment").length == 0) {
                $(commentListItemElement).find(".toggle-child-comments").remove()
              }
            })
          } else {
            $(`.comment-list-item-${commentId}`).remove()
          }

          // Todo: move reply count to container state
          const $popover = $(`.comment-popover-${commentId}`)
          if($popover) {
            const $marker = $(`[aria-describedby="${$popover.attr("id")}"]`)
            $marker.popover("hide")
          }
        }
      })
    }
  }

  toggleChildComments() {
    $(this.childCommentsTarget).toggleClass("d-block").toggleClass("d-none")
    $(this.childCommentsToggleIconTarget).toggleClass("fa-comment-minus").toggleClass("fa-comment-plus")
    
    const $popover = $(this.element).parents(".popover")
    if($popover) {
      const $marker = $(`[aria-describedby="${$popover.attr("id")}"]`)
      $marker.popover("update")
    }
  }

  toggleContentExpansion() {
    const originalHTML = this.contentWrapperTarget.innerHTML
    this.contentWrapperTarget.innerHTML = this.data.get("contentSwap")
    this.data.set("contentSwap", originalHTML)
    $(this.showLessTarget).toggleClass("d-none")
  }

  showEditModal(e) {
    e.preventDefault()
    const commentId = this.data.get("id")
    const editCommentUrl = $(e.target).attr("href")

    $.ajax({
      url: editCommentUrl,
      method: "GET",
      headers: {
        "X-CSRF-Token": Rails.csrfToken()
      },
      success: function(html) {
        $("body").append(html);
      }
    });
  }

  toggleAddressed() {
    const commentId = this.data.get("id")
    const updateCommentUrl = this.data.get("updateCommentUrl")
    const wrapperController = this.wrapperController

    $.ajax({
      url: updateCommentUrl,
      method: "PUT",
      data: {
        comment: {
          addressed: this.addressedCheckboxInputTarget.checked,
          freeze_timestamp: true,
        }
      },
      headers: {
        "X-CSRF-Token": Rails.csrfToken()
      },
      success: function(html) {
        $(`.comment-list-item-${commentId}`).each(function() {
          // If this comment is addressed and active, select/make active the now unaddressed version of it
          if($(this).hasClass("addressed") && $(this).hasClass("active")) {
            html = $(html).addClass("active")
          }

          // If this is an addressed comment and the other addressed comments are visible
          if($(html).hasClass("addressed") && $(".comment-list-item.addressed.show").length) {
            html = $(html).addClass("show")
          }

          const $that = $(this).replaceWith(html)
        })
      }
    });
  }


  get wrapperController() {
    return this.application.getControllerForElementAndIdentifier(
      $(this.element).parents(".comments-wrapper")[0], 
      "comments--wrapper"
    )
  } 

  _placeMarker() {
    const fileSystemObjectAttachmentType = this.data.get("fileSystemObjectAttachmentType")

    this[`_place${capitalizeFirstLetter(fileSystemObjectAttachmentType)}Marker`]()
  }

  _placeAudioMarker() {
    const id = this.data.get("id")
    const addressed = this.data.get("addressed") == "true"
    const authorInitials = this.data.get("authorInitials")
    const fileSystemObjectId = this.data.get("fileSystemObjectId")
    const timeStart = this.data.get("timeStart")
    const timeEnd = this.data.get("timeEnd")

    if(timeStart) {
      this.onControllerLoad(
        `waveform-wrapper-${fileSystemObjectId}`, 
        "waveform", 
        function(controller) {
          controller.placeCommentMarker({
            id,
            addressed,
            authorInitials,
            timeStart, 
            timeEnd, 
          })
        }
      )
    }
  }

  _placeImageMarker() {
    const id = this.data.get("id")
    const addressed = this.data.get("addressed") == "true"
    const authorName = this.data.get("authorName")
    const fileSystemObjectId = this.data.get("fileSystemObjectId")
    const canvasObject = this.data.get("canvasObject") && JSON.parse(this.data.get("canvasObject"))
    const popoverContent = this.data.get("popoverContent")

    if(canvasObject) {
      this.onControllerLoad(
        `image-annotator-wrapper-${fileSystemObjectId}`, 
        "image-annotator", 
        function(controller) {
          controller.placeCommentMarker({
            id, 
            addressed,
            authorName, 
            canvasObject,
            popoverContent,
          })
        }
      )
    }
  }

  _placePdfMarker() {
    const id = this.data.get("id")
    const addressed = this.data.get("addressed") == "true"
    const authorName = this.data.get("authorName")
    const fileSystemObjectId = this.data.get("fileSystemObjectId")
    const canvasObject = this.data.get("canvasObject") && JSON.parse(this.data.get("canvasObject"))
    const popoverContent = this.data.get("popoverContent")
    const page = this.data.get("page")

    if(canvasObject) {
      this.onControllerLoad(
        `image-annotator-wrapper-${fileSystemObjectId}-page-${page}`, 
        "image-annotator", 
        function(controller) {
          controller.placeCommentMarker({
            id, 
            addressed,
            authorName, 
            canvasObject,
            popoverContent,
          })
        }
      )
    }
  }

  _removeMarker() {
    const fileSystemObjectAttachmentType = this.data.get("fileSystemObjectAttachmentType")

    this[`_remove${capitalizeFirstLetter(fileSystemObjectAttachmentType)}Marker`]()
  }

  _removeAudioMarker() {
    const id = this.data.get("id")
    const fileSystemObjectId = this.data.get("fileSystemObjectId")

    this.onControllerLoad(
      `waveform-wrapper-${fileSystemObjectId}`, 
      "waveform", 
      function(controller) {
        controller.removeCommentMarker(id)
      }
    )
  }

  _removeImageMarker() {
    const id = this.data.get("id")
    const fileSystemObjectId = this.data.get("fileSystemObjectId")

    this.onControllerLoad(
      `image-annotator-wrapper-${fileSystemObjectId}`, 
      "image-annotator", 
      function(controller) {
        controller.removeCommentMarker(id)
      }
    )
  }

  _removePdfMarker() {
    const id = this.data.get("id")
    const fileSystemObjectId = this.data.get("fileSystemObjectId")
    const page = this.data.get("page")

    this.onControllerLoad(
      `image-annotator-wrapper-${fileSystemObjectId}-page-${page}`, 
      "image-annotator", 
      function(controller) {
        controller.removeCommentMarker(id)
      }
    )
  }
}
