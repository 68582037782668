import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "lineItemsTab" ]

  hideSidebar() {
    $("#client-nav").removeClass("right-sidebar-visible")
    $(".main-content").addClass("right-sidebar-hidden")
    this.sidebarController.hide()
  }

  showSidebar() {
    $("#client-nav").addClass("right-sidebar-visible")
    $(".main-content").removeClass("right-sidebar-hidden")
    this.sidebarController.show()
  }

  toggleTab(e) {
    e.preventDefault()
    const $tab = $(e.currentTarget)

    if($tab.hasClass("active")) {
      $tab.removeClass("active show")
      this.hideSidebar()
    } else {
      $tab.tab("show")
      this.showSidebar()
    }
  } 

  get sidebarController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("client-sidebar"), 
      "client-side--sidebar"
    )
  } 
}
