import axios from "axios"
import EventEmitter from "events"
import PQueue from "p-queue"
import throttle from "lodash.throttle"
import isObject from "lodash.isobject"
import { getExtension } from "utils/file"
import Upload from "./upload"

/**
 * This emits the following listenable events:
 *   progress - Overall uploader progress
 *   upload:progress - Upload progress - Args: upload, error
 *.  
 */
export default class MultipartFileUploader extends EventEmitter {

  /**
   * @param {Object} obj Params
   * @param {string[]} obj.allowedFileExtensions - Allowed file extensions (preceded with a `.`; i.e. `[".wav", ".mp3"]`)
   * @param {string} obj.baseUrl - The URL at which requests will be made
   * @param {number} obj.maxFileSize - Maximum file size in bytes
   */
  constructor({ allowedFileExtensions, baseUrl, maxFileSize }) {
    super()

    this.allowedFileExtensions = allowedFileExtensions
    this.baseUrl = baseUrl
    this.maxFileSize = maxFileSize
    this.uploads = {}
    this.queue = new PQueue({ 
      autoStart: true,
      concurrency: 5
    })
  }

  /**
   * Getters
   */
  get addedSize() {
    return Object.keys(this.uploads).length
  }

  get completedSize() {
    return Object.values(this.uploads).filter(upload => upload.completed).length
  }

  get progress() {
    return Number(((this.totalBytesSent / this.totalSize) * 100).toFixed(2))
  }

  get totalBytesSent() {
    return Object.values(this.uploads).reduce((total, upload) => total + upload.bytesSent, 0)
  }

  get totalSize() {
    return Object.values(this.uploads).reduce((total, upload) => total + upload.size, 0)
  }

  addFile = (file, additionalParams = {}, metadataFn = (file) => new Promise((resolve, reject) => resolve({}))) => {
    this.addFiles([file], additionalParams, metadataFn)
  }

  addFiles = (files, additionalParams = {}, metadataFn = (file) => new Promise((resolve, reject) => resolve({}))) => {
    const self = this

    const invalidFileExtensions = self._validateFileExtensions(files, self.allowedFileExtensions)
    const filesOverMaxFileSize = self._validateFileSizes(files, self.maxFileSize)

    if(invalidFileExtensions.length) {
      self._emitAddError("invalidFileExtensions", { invalidFileExtensions })
    } else if (filesOverMaxFileSize.length) {
      self._emitAddError("maxFileSize", { invalidFiles: filesOverMaxFileSize })
    } else {
      window.addEventListener("beforeunload", self._handleBeforeUnload)

      files.forEach((file) => {
        const upload = self._addFile(file, additionalParams, metadataFn)    
        self.totalBytes += upload.size
        upload.start()
        // Start is async!! emitUploadProgress shouldn't break without ID
        self._emitUploadProgress(upload)
        console.log("added file: ", upload.name)  
      })

      self._emitOverallProgress()
    }
  }

  removeFile = (id) => {
    this._removeFile(id)
  }

  _addFile = (file, additionalParams, metadataFn) => {
    const upload = new Upload({
      baseUrl: this.baseUrl,
      queue: this.queue,
      additionalParams,
      file,
      metadataFn,
    })

    this.uploads[upload.id] = upload

    upload.on("progress", () => {
      this._emitUploadProgress(upload)
      this._emitOverallProgress()
    })

    upload.on("completed", () => {
      if(this.completedSize === this.addedSize) {
        window.removeEventListener("beforeunload", this._handleBeforeUnload)
      }
      this._emitUploadCompleted(upload)
      this._emitOverallProgress()
    })

    upload.on("removed", () => {
      if(this.completedSize === this.addedSize) {
        window.removeEventListener("beforeunload", this._handleBeforeUnload)
      }
      this._emitUploadRemoved(upload)
      this._emitOverallProgress()
    })

    upload.on("error", error => {
      this._emitUploadProgress(upload)
      this._emitUploadError(upload, error)
      this._emitOverallProgress()
    })

    return upload
  }

  // This message will not work in Chrome any longer, but the unload prevention will. 
  // See: https://stackoverflow.com/a/40570214/981177
  _handleBeforeUnload = e => {
    const msg = "You've got files uploading. If you leave, your uploads will fail!"
    e.returnValue = msg
    return msg
  }

  _removeFile = (id) => {
    this.uploads[id].remove()
    delete this.uploads[id]

    this._emitOverallProgress()
  }

  _emitAddError = (type, data) => this.emit("add:error", type, data)

  _emitOverallProgress = throttle(() => {
    this.emit("progress", this.progress)
  }, 500, { 
    leading: true, 
    trailing: true,
  })

  _emitUploadError = (upload, error) => this.emit("upload:error", upload, error)
  _emitUploadCompleted = (upload) => this.emit("upload:completed", upload)
  _emitUploadRemoved = (upload) => this.emit("upload:removed", upload)
  _emitUploadProgress = (upload) => this.emit("upload:progress", upload)

  // Files can be FileEntry or File
  _validateFileExtensions = (files, validExtensions) => {
    const invalidFileExtensions = []

    files.forEach((file) => {
      const ext = getExtension(file)

      if(!validExtensions.includes(ext)) {
        invalidFileExtensions.push(ext)
      }
    })

    return [...new Set(invalidFileExtensions)] // Returns distinct results
  }

  // Returns true if all are below maxFileSize or if maxFileSize is null-y
  _validateFileSizes = (files, maxFileSize) => {
    const filesOverMaxFileSize = (filesToCheck, maxSize) => {
      return maxSize == null ? [] : filesToCheck.filter(file => file.size > maxSize)
    }

    if(isObject(maxFileSize)) {
      const extensions = Object.keys(maxFileSize)
      let invalidFiles = []
      extensions.forEach((extension) => {
        const filesToCheck = files.filter(file => getExtension(file) == extension)
        const invalidFilesWithExtension = filesOverMaxFileSize(filesToCheck, maxFileSize[extension])
        invalidFiles = invalidFiles.concat(invalidFilesWithExtension)
      })

      return [... new Set(invalidFiles)]
    } else {
      return filesOverMaxFileSize(files, maxFileSize)
    }
  }
}