import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]

  toggleContents(e) {
    const $target = $(e.currentTarget)
    const $div = $target.siblings("div")
    const $childTree = $div.siblings("ul:first")
    const $folderIcon = $div.find("i.fa-folder, i.fa-folder-open")
    if($childTree.hasClass("show")) {
      $div.removeClass("show")
      $childTree.removeClass("show")
      $folderIcon.removeClass("fa-folder-open").addClass("fa-folder")
      $target.removeClass("fa-minus-square").addClass("fa-plus-square")
    } else {
      $div.addClass("show")
      $childTree.addClass("show")
      $folderIcon.removeClass("fa-folder").addClass("fa-folder-open")
      $target.removeClass("fa-plus-square").addClass("fa-minus-square")
    }
  }
}