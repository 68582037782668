import { Controller } from "stimulus"
import Decimal from "decimal.js-light"

const calculateAndDisplayTotal = function(inputTargets, totalTarget, decimalMark) {
  let total = new Decimal(0)
  const stripRegEx = new RegExp("[^\\d\\" + decimalMark + "]", "g")

  $(inputTargets).each(function() {
    const value = $(this).val()
    const destroyed = $(this).parents(".nested-fields").find("[name$='[_destroy]']").val() === "1"
    if(value && !destroyed) {
      total = total.plus(new Decimal(value.replace(stripRegEx, "").replace(decimalMark, ".")))
    }
  })

  const totalFormatted = total.toString().replace(".", decimalMark)
  $(totalTarget).val(totalFormatted)
  $(totalTarget).maskMoney("mask");
}

export default class extends Controller {
  static targets = [ "input", "total" ]

  connect() {
    const that = this
    calculateAndDisplayTotal(that.inputTargets, that.totalTarget, that.data.get("decimalMark"))
    
    $("#project-line-items-container").on("cocoon:after-remove", function() {
      calculateAndDisplayTotal(that.inputTargets, that.totalTarget, that.data.get("decimalMark"))
    })
  }

  disconnect() {
    $("#project-line-items-container").unbind("cocoon:after-remove")
  }

  calculateAndDisplayTotal() {
    calculateAndDisplayTotal(this.inputTargets, this.totalTarget, this.data.get("decimalMark"))
  }
}