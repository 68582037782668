import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  switchInputType() {
    if($(this.inputTarget).is(":password")) {
      $(this.inputTarget).attr("type", "text")
    } else {
      $(this.inputTarget).attr("type", "password")
    }
  }
}