import ApplicationController from "controllers/application_controller"
import debounce from "lodash.debounce"

export default class extends ApplicationController {
  static targets = [ "additionalStorage", "cancelChangesLink", "confirmChangesBtn", "cost", "periodUnitToggleInput", "plan", "portfolioPlayerPlanHeader", "portfolioPlayerPrice", "portfolioPlayerTotalStorage", "portfolioPlayerTrialBanner", "selectedPlanName", "selectedPortfolioPlayer", "selectedTotalStorage", "summary", "yearlyToggle" ]

  connect() {
    const self = this

    self._updateState = debounce(async (showSpinner) => {
      await self._updateStateAsync(showSpinner)
    }, 500, {
      leading: false,
      trailing: true
    })

    self.initState = self._getState()
    self.updateState(false)
  }

  confirmChanges(e) {
    const self = this
    e.preventDefault()
    $(self.confirmChangesBtnTarget).addClass("disabled")

    $.ajax({
      url: self.data.get("confirmUrl"),
      method: "PUT",
      data: self._data(self._getState()),
      headers: {
        "X-CSRF-Token": Rails.csrfToken()
      },
      complete: () => {
        $(self.confirmChangesBtnTarget).removeClass("disabled")
      }
    })
  }

  resubscribe(e) {
    const self = this
    e.preventDefault()

    $(self.confirmChangesBtnTarget).addClass("disabled")
    const createHostedPageUrl = self.data.get("createHostedPageUrl")
    const state = self._getState()
    const data = self._data(state)
    const successUrl = this.data.get("createHostedPageSuccessUrl")

    Chargebee.init({ site: $("#chargebee-js").attr("data-cb-site") })
    Chargebee.registerAgain()
    const cbInstance = Chargebee.getInstance()
    cbInstance.openCheckout({
      hostedPage: function() {
        return $.ajax({
          type: "POST",
          data,
          url: createHostedPageUrl,
          headers: {
            "X-CSRF-Token": Rails.csrfToken()
          },
        })
      },
      close: function() {
        $(self.confirmChangesBtnTarget).removeClass("disabled")
      },
      success: function(hostedPageId) {
        if(self.data.get("hasTrialedPortfolioPlayer") === "false" && self.data.get("hasTrialedCollaboration") === "true" && state.collaborationPlanId != null && state.portfolioPlayer) {
          $.ajax({
            url: self.data.get("confirmUrl"),
            method: "PUT",
            data: {
              sync: true,
              ...data,
            },
            headers: {
              "X-CSRF-Token": Rails.csrfToken()
            },
            complete: () => {
              window.location.href = successUrl
            }
          })
        } else {
          window.location.href = successUrl
        }
      },
      error: function (response) {
        console.log(response.responseText)
      }
    })
  }

  decrementStorage() {
    this._addOrSubtractStorageBlock(false)
    this.updateState(true)
  }

  incrementStorage() {
    this._addOrSubtractStorageBlock(true)
    this.updateState(true)
  }

  togglePeriodUnit() {
    const self = this
    const checked = $(this.periodUnitToggleInputTarget).is(":checked")
    let planToToggle

    $(self.planTargets).each(function() {
      const plan = this

      if($(plan).attr("data-period-unit") == "year") {
        if(checked) {
          $(plan).removeClass("d-none")
        } else {
          $(plan).addClass("d-none")
        }
      } else {
        if(checked) {
          $(plan).addClass("d-none")
        } else {
          $(plan).removeClass("d-none")
        }
      }

      if($(plan).hasClass("active")) {
        planToToggle = plan
      }
    })

    if(checked) {
      this.data.set("periodUnit", "year")
    } else {
      this.data.set("periodUnit", "month")
    }

    this._updatePortfolioPlayerInfo()

    if(planToToggle) {
      const plan = self.planTargets.filter(planTarget => planTarget.dataset.planId === planToToggle.dataset.oppositeBillingIntervalPlanId)
      if(plan.length > 0) {
        $(plan).trigger("click") // This will trigger update state
      } else {
        $(planToToggle).trigger("click") // This will trigger update state
      }
    } else {
      this.updateState(true)
    }
  }

  toggleProduct(e) {
    const product = e.currentTarget.dataset.product

    if(product === "collaboration") {
      const planId = e.currentTarget.dataset.planId
      const planName = e.currentTarget.dataset.planName
      const totalStorage = e.currentTarget.dataset.totalStorage
      const includesPortfolioPlayer = e.currentTarget.dataset.includesPortfolioPlayer === "true"

      $(e.currentTarget).removeClass("active")
      $(e.currentTarget).siblings(".selectable-product").removeClass("active")

      if(this.data.get("collaborationPlanId") === planId) {
        this.data.set("collaborationPlanId", "")
        this.data.set("collaborationPlanName", "")
        this.data.set("collaborationPlanTotalStorage", "")
        this.data.set("collaborationPlanIncludesPortfolioPlayer", false)

        if(includesPortfolioPlayer) {
          $(e.currentTarget).removeClass("active")
        }
      } else {
        $(e.currentTarget).addClass("active")
        this.data.set("collaborationPlanId", planId)
        this.data.set("collaborationPlanName", planName)
        this.data.set("collaborationPlanTotalStorage", totalStorage)
        this.data.set("collaborationPlanIncludesPortfolioPlayer", includesPortfolioPlayer)
      }

      if(includesPortfolioPlayer) {
        $(".selectable-product[data-product='portfolio_player']").addClass("active")
      }

      if(!includesPortfolioPlayer && this.data.get("portfolioPlayer") !== "true") {
        $(".selectable-product[data-product='portfolio_player']").removeClass("active")
      }
    } else if(product === "portfolio_player") {
      if(this.data.get("collaborationPlanIncludesPortfolioPlayer") === "true") {
        return
      } else {
        if(this.data.get("portfolioPlayer") === "true") {
          $(e.currentTarget).removeClass("active")
          this.data.set("portfolioPlayer", false)
        } else {
          $(e.currentTarget).addClass("active")
          this.data.set("portfolioPlayer", true)
        }
      }
    }
    
    const state = this._getState()
    if(state.collaborationPlanId) {
      $(this.portfolioPlayerTotalStorageTarget).addClass("d-none")
    } else {
      $(this.portfolioPlayerTotalStorageTarget).removeClass("d-none")
    }
    
    this._updatePortfolioPlayerInfo()
    this.updateState(true)
  }

  updateState(showSpinner) {
    const state = this._getState()
    this.selectedPlanNameTarget.innerHTML = `Collaboration Plan: <strong>${state.collaborationPlanName || "N/A"}</strong>`
    this.selectedPortfolioPlayerTarget.innerHTML = `Portfolio Player: <strong>${state.portfolioPlayer || state.includesPortfolioPlayer ? "Yes" : "No"}</strong>`
    this.selectedTotalStorageTarget.innerHTML = `Storage: <strong>${state.collaborationPlanTotalStorage || this.data.get("portfolioPlayerPlanTotalStorage")} GB ${state.additionalStorage > 0 ? `+ ${state.additionalStorage} GB` : ""}</strong>`

    // FIX DEBOUNCING
    this._updateState(showSpinner)
  }

  _addOrSubtractStorageBlock(trueAdd_falseSubtract) {
    const addl = parseInt(this.additionalStorageTarget.innerHTML.split(" GB")[0])
    const totalAddl = trueAdd_falseSubtract ? addl + 256 : addl - 256

    if(totalAddl < 0) {
      return
    }

    this.additionalStorageTarget.innerHTML = `${totalAddl} GB`
    this.data.set("additionalStorage", totalAddl)
  }

  _data(state) {
    const self = this
    const interval = `${capitalizeFirstLetter(state.periodUnit)}ly`

    const data = {
      plan_id: state.collaborationPlanId || self.data.get(`portfolioPlayerPlan${interval}Id`),
      addons: [],
    }

    if(state.collaborationPlanId && state.portfolioPlayer && !state.includesPortfolioPlayer) {
      const portfolioPlayerAddonId = self.data.get(`portfolioPlayerAddon${interval}Id`)

      data.addons.push({
        id: portfolioPlayerAddonId,
        quantity: 1,
      })
    }

    if(state.additionalStorage > 0) {
      const additionalStorageAddonId = self.data.get("additionalStorageAddonId")

      data.addons.push({
        id: additionalStorageAddonId,
        quantity: state.additionalStorage / 256,
      })
    }

    return data
  }

  _getState() {
    const collaborationPlanId = this.data.get("collaborationPlanId") && this.data.get("collaborationPlanId").length ? this.data.get("collaborationPlanId") : null
    const collaborationPlanName = this.data.get("collaborationPlanName") && this.data.get("collaborationPlanName").length ? this.data.get("collaborationPlanName") : null

    return {
      additionalStorage: parseInt(this.data.get("additionalStorage") || 0),
      collaborationPlanId,
      collaborationPlanName,
      collaborationPlanTotalStorage: parseInt(this.data.get("collaborationPlanTotalStorage") || 0),
      periodUnit: this.data.get("periodUnit"),
      portfolioPlayer: this.data.get("portfolioPlayer") === "true",
      includesPortfolioPlayer: this.data.get("collaborationPlanIncludesPortfolioPlayer") === "true",
    }
  }

  _updatePortfolioPlayerInfo() {
    const interval = `${capitalizeFirstLetter(this.data.get("periodUnit"))}ly`

    if(this.data.get("collaborationPlanIncludesPortfolioPlayer") === "true") {
      this.portfolioPlayerPriceTarget.innerHTML = this.data.get("portfolioPlayerPlanZeroPrice")
      
      if(this.hasPortfolioPlayerTrialBannerTarget) {
        this.portfolioPlayerTrialBannerTarget.classList.add("d-none")
      }
    } else {
      this.portfolioPlayerPriceTarget.innerHTML = this.data.get(`portfolioPlayerPlan${interval}Price`)
      
      if(this.hasPortfolioPlayerTrialBannerTarget) {
        this.portfolioPlayerTrialBannerTarget.classList.remove("d-none")
      }
    }

    $(this.portfolioPlayerPlanHeaderTarget).removeClass("plan-header-month plan-header-year").addClass(`plan-header-${this.data.get("periodUnit")}`)
  }

  async _updateStateAsync(showSpinner) {
    const self = this
    const url = self.data.get("estimateUrl")
    const state = self._getState()
    const data = self._data(state)
    const initData = self._data(self.initState)

    $(self.confirmChangesBtnTarget).addClass("disabled")
  
    if(showSpinner) {
      this.costTarget.innerHTML = `<div class="mt-3"><i class="fas fa-circle-notch fa-spin fa-2x"></i></div>`
    }

    // If there's no changes, kick out.
    if(
      (
        initData.plan_id == data.plan_id &&
        initData.addons.length === data.addons.length &&
        initData.addons.every(addon => {
          return data.addons.some(_addon => {
            return addon.id === _addon.id && addon.quantity === _addon.quantity
          })
        })
      )

      ||

      (
        state.collaborationPlanId == null &&
        state.portfolioPlayer == false
      )
    ) {
      $(self.confirmChangesBtnTarget).removeClass("d-block").addClass("d-none")
      $(self.cancelChangesLinkTarget).addClass("d-none")

      if(state.collaborationPlanId == null && state.portfolioPlayer == false) {
        self.costTarget.innerHTML = "Select a product to see pricing"
      } else {
        self.costTarget.innerHTML = ""
      }
      return
    }

    const response = await $.ajax({
      url,
      method: "GET",
      data,
      headers: {
        "X-CSRF-Token": Rails.csrfToken()
      },
    })
    
    let nextInvoiceAmountFormatted
    if(response.now.next_invoice_estimate) {
      nextInvoiceAmountFormatted = ` for ${response.now.next_invoice_estimate.amount_due_formatted}`
    } else {
      if(response.renew && response.renew.invoice_estimate) {
        nextInvoiceAmountFormatted = ` for ${response.renew.invoice_estimate.total_formatted}`
      } else {
        nextInvoiceAmountFormatted = ""
      }
    }

    let innerHTML = `<div class="mt-3">Due Today:<strong class="d-block">${response.now.invoice_estimate ? response.now.invoice_estimate.amount_due_formatted || response.now.invoice_estimate.total_formatted : "Nothing"}</strong></div>`
    innerHTML += `<small class="d-block mt-2">Subscription will renew on ${new Intl.DateTimeFormat().format(new Date(response.now.subscription_estimate.next_billing_at * 1000))}${nextInvoiceAmountFormatted}</small>`
    if((response.now.invoice_estimate && response.now.invoice_estimate.taxes.length > 0) || (response.now.next_invoice_estimate && response.now.next_invoice_estimate.taxes.length > 0) || (response.renew && response.renew.invoice_estimate && response.renew.invoice_estimate.taxes.length > 0)) {
      innerHTML += `<small class="d-block"><em>Prices include applicable tax</em></small>`
    }

    self.costTarget.innerHTML = innerHTML
    $(self.confirmChangesBtnTarget).removeClass("disabled d-none").addClass("d-block")
    $(self.cancelChangesLinkTarget).removeClass("d-none")

    return response
  }
}
