import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [ ]

  openModal(e) {
    e.preventDefault()
    const commentId = this.data.get("id")
    const url = this.element.href

    $.ajax({
      url: url,
      method: "GET",
      data: {
        comment: {
          author_name: getItem("authorName")
        }
      },
      success: function(html) {
        $("body").append(html);
      }
    });
  }
}
