// Pass a maximum to determine if hours should be included
// i.e. when passing 0 for the left side of 00:00:00/03:30:54, pass the maximum of 12600 (seconds in 3:30:54)
export function hhMMSS(seconds, maximum) {
  seconds = Math.floor(seconds)

  if(maximum == null) {
    maximum = seconds
  }

  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor(seconds / 60) % 60
  const secondsRemainder = seconds % 60

  return `${maximum > 3600 ? `${`0${hours}`.slice(-2)}:` : ''}${`0${minutes}`.slice(-2)}:${`0${secondsRemainder}`.slice(-2)}`
}