import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [ "bulkActionsBtn", "rowCheckbox", "selectAllCheckbox" ]

  connect() {
    this.rows = {}
  }

  delete() {
    const url = this.data.get("deleteUrl")
    let data = {}
    
    if(this.data.get("allSelected") == "true") {
      const rootId = this.data.get("rootId")
      let msg

      if(rootId) {
        data.root_id = rootId
        msg = `Are you sure you want to delete everything inside "${this.data.get("rootName")}"`
      } else {
        const projectId = this.data.get("projectId")
        data.project_id = projectId
        msg = `Are you sure you want to delete everything inside the project titled "${this.data.get("projectName")}"`
      }

      if(!confirm(msg)) {
        return;
      }
    } else {
      data.ids = []
      $(this.rowCheckboxTargets).filter(":checked").each(function() {
        data.ids.push($(this).val())
      })

      if(!confirm(`Are you sure you want to delete ${data.ids.length} files or folders?`)) {
        return;
      }
    }

    $.ajax({
      type: "DELETE",
      url,
      data,
      headers: {
        "X-CSRF-Token": Rails.csrfToken()
      }
    })
  }

  download() {
    const url = this.data.get("downloadUrl")

    const form = document.createElement("form")
    form.setAttribute("method", "POST")
    form.setAttribute("action", url)

    const input = document.createElement("input")
    input.setAttribute("type", "hidden")
    input.setAttribute("name", "authenticity_token")
    input.setAttribute("value", Rails.csrfToken())
    form.appendChild(input)

    const addId = (id) => {
      const input = document.createElement("input")
      input.setAttribute("type", "hidden")
      input.setAttribute("name", "ids[]")
      input.setAttribute("value", id)
      form.appendChild(input)
    }
    
    if(this.data.get("allSelected") == "true") {
      const rootId = this.data.get("rootId")

      if(rootId) {
        addId(rootId)
      }
    } else {
      $(this.rowCheckboxTargets).filter(":checked").each(function() {
        addId($(this).val())
      })
    }

    document.getElementsByTagName("body")[0].appendChild(form)
    form.submit()
  }

  move() {
    const url = this.data.get("moveToUrl")
    let data = {}

    if(this.data.get("allSelected") == "true") {
      const rootId = this.data.get("rootId")

      data.root_id = rootId
    } else {
      data.ids = []
      $(this.rowCheckboxTargets).filter(":checked").each(function() {
        data.ids.push($(this).val())
      })
    }

    $.ajax({
      type: "POST",
      url,
      data,
      headers: {
        "X-CSRF-Token": Rails.csrfToken()
      },
      error: (response) => {
        if(response.responseJSON.error) {
          alert(response.responseJSON.error)
        }
      }
    })
  }

  rowConnected() {
    if(this.data.get("allSelected") == "true") {
      $(this.rowCheckboxTargets).prop("checked", true)
    }
  }

  toggleAll() {
    if(this.hasRowCheckboxTarget) {
      const checked = this.data.get("allSelected") == "true"
      $(this.rowCheckboxTargets).prop("checked", !checked)
      this.updateState()
    }
  }

  updateState() {
    const self = this
    const checkedTargets = this.rowCheckboxTargets.filter(target => $(target).is(":checked"))
    const uncheckedTargets = this.rowCheckboxTargets.filter(target => !$(target).is(":checked"))

    let allChecked = false
    if (checkedTargets.length == this.rowCheckboxTargets.length) {
      allChecked = true
    }

    if(checkedTargets.length > 0) {
      $(this.element).addClass("show-bulk-checkboxes")
    } else {
      $(this.element).removeClass("show-bulk-checkboxes")
    }

    let allUnchecked = false
    if(uncheckedTargets.length == this.rowCheckboxTargets.length) {
      allUnchecked = true
      $(this.bulkActionsBtnTarget).addClass("d-none").removeClass("d-block")
    } else {
      $(this.bulkActionsBtnTarget).removeClass("d-none").addClass("d-block")
    }

    const updateChecked = (checked) => {
      $(self.selectAllCheckboxTarget).prop("checked", checked)
      self.data.set("allSelected", checked)
    }

    if(allChecked) {
      updateChecked(true)
    } else {
      updateChecked(false)
    }
  }
}
