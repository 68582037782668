import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [ ]

  connect() {
    let create = false
    const that = this
    if(this.data.get("create") == "true") {
      create = true
    }

    $(this.element).selectize({
      create: create,
      maxItems: 1,
      onChange: function(value) {
        // Persist only works when backspaced, not when selecting another,
        // so we're using this to remove user created options that were deselected.
        // See: https://github.com/selectize/selectize.js/issues/1081
        if(value) {
          var self = this
          if(!self.settings.persist && !self.userOptions.hasOwnProperty(value)) {
            $.each(self.userOptions, function(i) {
              self.removeOption(i, true)
            })
          }
        }
        that.element.dispatchEvent(new Event("change"))
      },
      persist: false,
      // sortField: "text", // removed to disable auto-sorting
    })
  }

  disconnect() {
    $(this.element).selectize()[0].selectize.destroy();
  }
}
