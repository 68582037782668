import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  openCheckout(e) {
    e.preventDefault()
    const createHostedPageUrl = this.data.get("createHostedPageUrl")
    const planId = this.data.get("planId")
    const successUrl = this.data.get("successUrl")

    Chargebee.init({ site: $("#chargebee-js").attr("data-cb-site") })
    Chargebee.registerAgain()
    const cbInstance = Chargebee.getInstance()
    cbInstance.openCheckout({
      hostedPage: function() {
        return $.ajax({
          type: "POST",
          data: {
            plan_id: planId,
          },
          url: createHostedPageUrl,
          headers: {
            "X-CSRF-Token": Rails.csrfToken()
          },
        })
      },
      success: function(hostedPageId) {
        window.location.href = successUrl
      },
      error: function (response) {
        console.log(response.responseText)
      }
    })
  }
}
