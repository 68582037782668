import axios from "axios"
import axiosRetry from "axios-retry"

const client = axios.create()

client.interceptors.request.use((config) => {
  // If this is a request to Filepass' back-end, pass the current CSRF token
  if(config.url == null || config.url.length === 0 || config.url[0] === "/" || config.url.includes(window.location.origin)) {
    config.headers["X-CSRF-Token"] = Rails.csrfToken()
  }

  return config
})

axiosRetry(client, { 
  retryDelay: axiosRetry.exponentialDelay,
  retries: 5
})

export default client