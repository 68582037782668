import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "parentIdInput", "selectedFolder" ]

  selectFolder(e) {
    const $div = $(e.currentTarget)
    const id = $div.attr("data-id")

    if(this.data.get("rootMessage") == null) {
      this.data.set("rootMessage", $(this.selectedFolderTarget).text())
    }

    $div.closest(".file-tree.root").find(".selected").removeClass("selected")
    if(this.parentIdInputTarget.value == id) {
      this.parentIdInputTarget.value = ""
      $(this.selectedFolderTarget).text(this.data.get("rootMessage"))
    } else {
      $div.addClass("selected")
      this.parentIdInputTarget.value = id
      $(this.selectedFolderTarget).html($div.html())
    }
  }
}