import ApplicationController from "controllers/application_controller"

const randomInteger = function(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export default class extends ApplicationController {
  connect() {
    const self = this
    const url = self.data.get("url")

    if(self.data.get("processing") == "true") {
      setTimeout(function() {
        $.ajax({
        url: url,
          method: "GET",
          headers: {
            "X-CSRF-Token": Rails.csrfToken()
          },
          success: function(html) {
            $(self.element).replaceWith(html)

            if(window.Playlist && window.Playlist.tracks && window.Playlist.tracks.length == 1) {
              const firstTrack = window.Playlist.tracks[0]
              window.Playlist.setupTrack(firstTrack)
            }
          }
        })
      }, randomInteger(4500, 5500))
    }
  }
}
