import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [ "formContainer", "input" ]

  hideFolderFormContainer(e) {
    // Esc (keycode 27) or blur
    if(e.type !== "keydown" || e.which == 27) {
      $(this.formContainerTarget).addClass("d-none")
      const $input = $(this.inputTarget)
      $input.val("")
    }
  }

  showFolderFormOnKeydown(e) {
    // Shift+F
    if(e.shiftKey 
      && e.which == 70 
      && !$(this.formContainerTarget).is(":visible")
      && !$(e.target).is("input, textarea, [contenteditable='true']")
    ) {
      e.preventDefault()
      $(this.formContainerTarget).removeClass("d-none")
      const $input = $(this.inputTarget)
      $input.focus()
    }
  }

  showFolderForm() {
    $(this.formContainerTarget).removeClass("d-none")
    const $input = $(this.inputTarget)
    $input.focus()
  }
}
