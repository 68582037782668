import axios from "axios"
import EventEmitter from "events"
import client from "http_client"
import throttle from "lodash.throttle"

const partSize = 1024 * 1024 * 20 // 20 megabytes

export default class Upload extends EventEmitter {
  /**
   * @param {Object} obj Params
   * @param {Object} obj.additionalParams - Additional parameters to upload with the file
   * @param {string} obj.baseUrl - The URL at which requests will be made
   * @param {File} obj.file - Javascript File object to upload
   * @param {Function} obj.metadataFn - Function that returns a promise. Must resolve to an object of additional metadata (can be empty).
   * @param {PQueue} obj.queue - Queue to which file part upload requests should be added
   */
  constructor({ 
    additionalParams,
    baseUrl,
    file,
    metadataFn,
    queue,
  }) {
    super()

    this.additionalParams = additionalParams
    this.baseUrl = baseUrl
    this.file = file
    this.metadataFn = metadataFn
    this.queue = queue
    this.id = Math.random().toString(36).substr(2, 9)
    this.uploadId = null
    this.key = null
    this.parts = []
    this.canceled = false
    this.completed = false
    this.error = null
    this.source = null // Axios CancelToken source
  }

  /**
   * Getters
   */
  get bytesSent() {
    return this.parts.reduce((total, part) => total + part.bytesSent, 0)
  }

  get erred() {
    return this.error != null
  }

  get name() {
    return this.file.name
  }

  get progress() {
    return Number(((this.bytesSent / this.size) * 100).toFixed(2))
  }

  get size() {
    return this.file.size
  }

  remove = () => {
    client.delete(`${this.baseUrl}/${this.uploadId}`, {
      params: {
        key: this.key
      }
    })
    .then((response) => {
      // handle success
      this.emit("removed")
      console.log("DELETED!")
    })
    .catch(this._handleFailure)
  }

  start = () => {
    if(this.uploadId) {
      this._restart()
    } else {
      this._start()
    }
  }
  restart = this.start

  _complete = () => {
    const self = this

    self.metadataFn(self.file).then((additionalMetadata) => {
      client.post(`${self.baseUrl}/${self.uploadId}/complete`, {
        key: self.key,
        parts: self.parts.map(part => ({
          etag: part.etag,
          part_number: part.number,
        })),
        additional_metadata: additionalMetadata
      })
      .then((response) => {
        // handle success
        self.completed = true
        self.emit("completed")
      })
      .catch(self._handleFailure)
    })
  }

  _emitProgress = throttle(() => {
    // Don't emit progress if this file just erred out
    if(!this.erred) {
      this.emit("progress")
    }
  }, 500, { 
    leading: true, 
    trailing: true,
  })

  _handleFailure = (error) => {
    // handle error
    this.error = error

    if(error.response) {
      console.log("UPLOAD #", this.id, " RESPONSE ERROR: ", error)

      if(error.response.headers["content-type"] == "application/xml") {
        this.emit("error", error.response.statusText)
      } else {
        this.emit("error", error.response.data.error ? error.response.data.error : error.response.data)
      }
    } else if (error.request) {
      console.log("UPLOAD #", this.id, " REQUEST ERROR: ", error)
      this.emit("error", error.request.responseText.length ? error.request.responseText : "Error. Please try restarting the upload.")
    } else {
      console.log("UPLOAD #", this.id, " INTERNAL ERROR: ", error)
      this.emit("error", error.message)
    }
  }

  _handleStartSuccess = (response) => {
    const self = this

    // handle success
    self.uploadId = response.data.upload_id
    self.key = response.data.key
    self.parts = response.data.urls.map((url, index) => {
      if(self.parts[index]) {
        self.parts[index].url = url

        if(self.parts[index].etag == null) {
          self.parts[index].bytesSent = 0
        }

        return self.parts[index]
      } 

      let offset = partSize * index
      let endingByte = offset + partSize

      if(index == response.data.urls.length - 1) {
        const lastPartSize = self.file.size - (partSize * index)
        offset = self.file.size - lastPartSize
        endingByte = offset + lastPartSize
      }
      
      return {
        bytesSent: 0,
        chunk: self.file.slice(offset, endingByte),
        number: index + 1,
        etag: null,
        size: endingByte - offset,
        url,
      }
    })

    console.log(self)
    self._uploadParts()
  }

  _start = () => client.post(this.baseUrl, {
      filename: this.file.name,
      size: this.file.size,
      ...this.additionalParams
    })
    .then(this._handleStartSuccess)
    .catch(this._handleFailure)

  _restart = () => {
    this.canceled = false
    this.error = null

    client.get(`${this.baseUrl}/${this.uploadId}`, {
      params: {
        key: this.key,
        size: this.file.size,
      }
    })
    .then(this._handleStartSuccess)
    .catch(this._handleFailure)
  }

  _uploadParts = async () => {
    const self = this
    self.source = axios.CancelToken.source()

    await self.queue.addAll(
      self.parts.filter(part => part.etag == null).map((part, index, parts) => {
        return () => {
          // The token cancels parts that are already executing, whereas this will halt parts 
          // that haven't started yet by allowing them to complete.
          if(self.canceled) {
            console.log("UPLOAD #", self.id, " PART #", part.number, " SKIPPED")
            return Promise.resolve()
          }

          // Client will retry
          // Change client.put to client.post to test generating an XML error from AWS
          return client.put(part.url, part.chunk, {
            onUploadProgress: (e) => {
              part.bytesSent = e.loaded
              self._emitProgress()
            },
            cancelToken: self.source.token
          })
          .then((response) => {
            part.bytesSent = part.size
            part.etag = response.headers.etag.replace(/\"/g, "")
            console.log("UPLOAD #", self.id, " PART #", part.number, " COMPLETED")
            self._emitProgress()
          })
          .catch((error) => {
            // handle cancelled request or error
            if(axios.isCancel(error)) {
              console.log("UPLOAD #", self.id, " PART #", part.number, " CANCELED: ", error.message)
            } else {
              // Cancel this token so the other parts quit executing
              self.source.cancel()
              self.canceled = true

              if(error.response) {
                console.log("UPLOAD #", self.id, " PART #", part.number, " RESPONSE ERROR: ", error.response)
              } else if (error.request) {
                console.log("UPLOAD #", self.id, " PART #", part.number, " REQUEST ERROR: ", error.request)
              } else {
                console.log("UPLOAD #", self.id, " PART #", part.number, " INTERNAL ERROR: ", error.message)
              }

              self._handleFailure(error)
            }
          })
        }
      })
    )

    // If all parts uploaded successfully, complete the multipart upload
    if(self.parts.filter(part => part.etag == null).length == 0) {
      self._complete()
    }
  }
}