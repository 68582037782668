const modal = {
  show: function({ title, content, onShow, onShown }) {
    const modalMarkup = `<div class="modal fade" tabindex="-1" role="dialog" data-controller="modal">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content bg-lightest">
          ${title ? `
            <div class="modal-header bg-none">
              <h3 class="modal-title">${title}</h3>
            </div>
          ` : ""}
          ${content}
        </div>
      </div>
    </div>`
    const $modal = $(modalMarkup)
    $("body").append($modal)
    $modal.on("show.bs.modal", function() {
      if(onShow) {
        onShow()
      }
    }).on("shown.bs.modal", function() {
      if(onShown) {
        onShown()
      }
    }).modal("show")
  },
};

export function showClientUploadsUpsellModal() {
  modal.show({
    content: `
      <div class="modal-header bg-none">
        <h3 class="modal-title text-danger">Upgrade to use client uploads!</h3>
      </div>
      <div class="modal-body">
        <p>Take advantage of a simpler workflow by sending your clients a special link, allowing them to upload files with ease.</p>
        <p>To upgrade your plan, please visit your billing page to configure your subscription.</p>
        <div class="row mt-3">
          <div class="col text-right">
            <button class="btn btn-white" data-dismiss="modal" type="button">
              Cancel
            </button>
            <a href="/billing" class="btn btn-primary">Go to my billing page</a>
          </div>
        </div>
      </div>
    `
  })
}

export function showCollaborationUpsellModal(trialAvailable) {
  modal.show({
    content: `
      <div class="modal-header bg-none">
        <h3 class="modal-title text-danger">Upgrade to use collaboration features!</h3>
      </div>
      <div class="modal-body">
        <p>${trialAvailable ? "Try a 14-day free trial of" : "Use"} Filepass collaboration features to simplify your workflow.</p>
        <p>To upgrade your plan, please visit your billing page to configure your subscription.</p>
        <div class="row mt-3">
          <div class="col text-right">
            <button class="btn btn-white" data-dismiss="modal" type="button">
              Cancel
            </button>
            <a href="/billing" class="btn btn-primary">Go to my billing page</a>
          </div>
        </div>
      </div>
    `
  })
}

export function showPortfolioPlayerUpsellModal(trialAvailable) {
  modal.show({
    content: `
      <div class="modal-header bg-none">
        <h3 class="modal-title text-danger">Upgrade to use the portfolio player!</h3>
      </div>
      <div class="modal-body">
        <p>${trialAvailable ? "Try a 14-day free trial of" : "Use"} the best lossless audio player in the business to showcase your work on your website.</p>
        <p>To upgrade your plan or add the portfolio player as an addon to your current plan, please visit your billing page to configure your subscription.</p>
        <div class="row mt-3">
          <div class="col text-right">
            <button class="btn btn-white" data-dismiss="modal" type="button">
              Cancel
            </button>
            <a href="/billing" class="btn btn-primary">Go to my billing page</a>
          </div>
        </div>
      </div>
    `
  })
}

export function showInvalidFileExtensionsModal(allowedFileExtensions, invalidFileExtensions) {
  modal.show({
    content: `
      <div class="modal-header bg-none">
        <h3 class="modal-title text-danger">Invalid file type${invalidFileExtensions.length > 1 ? "s" : ""}: <span class="text-default">${invalidFileExtensions.join(", ")}</span></h3>
      </div>
      <div class="modal-body">
        <p>Try uploading one of the following instead: <strong>${allowedFileExtensions.join(", ")}</strong></p>
        <div class="row mt-3">
          <div class="col text-right">
            <button class="btn btn-primary" data-dismiss="modal" type="button">
              Got it
            </button>
          </div>
        </div>
      </div>
    `
  })
}

export function showMaxFileSizeModal(msg, secondaryMsg = "Try uploading a smaller file instead.") {
  modal.show({
    content: `
      <div class="modal-header bg-none">
        <h3 class="modal-title text-danger">${msg}</h3>
      </div>
      <div class="modal-body">
        <p>${secondaryMsg}</p>
        <div class="row mt-3">
          <div class="col text-right">
            <button class="btn btn-primary" data-dismiss="modal" type="button">
              Got it
            </button>
          </div>
        </div>
      </div>
    `
  })
}

export function showMonthlyUploadLimitReachedModal() {
  modal.show({
    content: `
      <div class="modal-header bg-none">
        <h3 class="modal-title text-danger">Monthly upload limit reached!</h3>
      </div>
      <div class="modal-body">
        <p>You've hit your upload limit for this month! To upgrade your plan, please visit your billing page to configure your subscription.</p>
        <div class="row mt-3">
          <div class="col text-right">
            <button class="btn btn-white" data-dismiss="modal" type="button">
              Cancel
            </button>
            <a href="/billing" class="btn btn-primary">Go to my billing page</a>
          </div>
        </div>
      </div>
    `
  })
}
  
export function showTotalStorageLimitReachedModal() {
  modal.show({
    content: `
      <div class="modal-header bg-none">
        <h3 class="modal-title text-danger">Total storage limit reached!</h3>
      </div>
      <div class="modal-body">
        <p>You're out of storage! To upgrade your plan or add additional storage, please visit your billing page to configure your subscription.</p>
        <div class="row mt-3">
          <div class="col text-right">
            <button class="btn btn-white" data-dismiss="modal" type="button">
              Cancel
            </button>
            <a href="/billing" class="btn btn-primary">Go to my billing page</a>
          </div>
        </div>
      </div>
    `
  })
}