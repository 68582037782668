import { Howl } from "howler"
import EventEmitter from "events"

export default class AudioPlayer extends EventEmitter {
  constructor(opts) {
    super()

    this.url = opts.url
    this.howler = null
    this._playing = false // Only used for _progressFn
    this._queuedSeekPct = null

    if(opts.keyboard) {
      document.addEventListener("keydown", this._keydownFn)
    }
  }

  destroy = () => {
    if(this.howler) {
      this.howler.off() // Remove event listener
      this.howler.stop() // Stop playback
      this.howler.unload() // Remove howler from pool
      this.howler = null // Destroy it
    }

    document.removeEventListener("keydown", this._keydownFn)
  }


  duration = () => this.howler ? this.howler.duration() : 0

  end = () => {
    this._playing = false

    this.emit("end")
  }

  stop = () => {
    this._playing = false

    if(this.howler) {
      this.howler.stop()
    }

    this.emit("stop")
  }

  pause = () => {
    this._playing = false

    if(this.howler) {
      this.howler.pause()
    }

    this.emit("pause")
    this._progressFn(false)
  }

  play = (seekPct) => {
    const self = this
    self._playing = true

    const seekFn = function() {
      if(seekPct != null) {
        self.howler.seek(seekPct * self.howler.duration())
      } else if (self._queuedSeekPct != null) {
        self.howler.seek(self._queuedSeekPct * self.howler.duration())
      }
      
      self._queuedSeekPct = null
    }

    if(self.howler) {
      seekFn()
    } else {
      self.howler = self._createHowler()
      self.howler.once("load", seekFn)
    }

    if(!self.howler.playing()) {
      // Without passing the sound ID, Howler adds a sound sometimes
      // See: https://github.com/goldfire/howler.js/issues/1156
      if(self.howler._sounds.length) {
        self.howler.play(self.howler._sounds[0]._id) 
      } else {
        self.howler.play()
      }
      self._progressFn(true)
    }

    self.emit("play")
  }

  get playing() {
    return this.howler && this.howler.playing()
  }

  _progressFn = (repeat) => {
    const duration = this.howler ? this.howler.duration() : 0
    let seek = 0
    
    if(this.howler && this._queuedSeekPct == null) {
      seek = this.howler.seek()
    } else if (this.howler && this._queuedSeekPct) {
      seek = this._queuedSeekPct * this.howler.duration()
    }

    const progressObj = {
      duration: duration,
      // Sometimes this.howler.seek() returns a Howler instance
      // See: https://github.com/goldfire/howler.js/issues/1189
      seek: typeof seek === "number" ? seek : this.howler._sounds[0]._seek,
    }
    this.emit("progress", progressObj)

    if(this.howler && this._playing && repeat) {
      window.requestAnimationFrame(this._progressFn)
    }
  }

  seek = (seekPct, forcePlay = false) => {
    if(this._playing || forcePlay) {
      this.play(seekPct)
    } else {
      this._queuedSeekPct = seekPct

      if(this.howler) {
        this._progressFn(false)
      } else {
        this.howler = this._createHowler()
        this.howler.once("load", () => this._progressFn(false))
      }
    }
  }

  seekPos = () => {
    return this.howler ? this.howler.seek() : 0
  }

  _createHowler = () => {
    return new Howl({
      html5: true,
      src: [this.url],
      onend: this.end,
    })
  }

  _keydownFn = (e) => {
    // Spacebar
    if (
      e.which == 32 
      && !(
        e.target.tagName === "INPUT" || //   !$(e.target).is("input, textarea, [contenteditable='true']")
        e.target.tagName === "TEXTAREA" ||
        e.target.getAttribute("contenteditable") === "true"
      )
    ) {
      e.preventDefault()
      if(this.playing) {
        this.pause()
      } else {
        this.play()
      }
    }
  }
}