import { Controller } from "stimulus"

const scrollToMobileCard = function() {
  // If this is a collapsed view, scroll down to the balance at the bottom.
  if($(window).width() < 992) {
    scrollToEl($("#mobile-card"))
  }
}

export default class extends Controller {
  static targets = [ ]

  showSidebar() {
    $("#client-nav").addClass("right-sidebar-visible")
    $(".main-content").removeClass("right-sidebar-hidden")
    this.sidebarController.show()
  }

  showLineItems(e) {
    e.preventDefault()

    const scrollToMobileCard = function() {
      // If this is a collapsed view, scroll down to the balance at the bottom.
      if($(window).width() < 992) {
        scrollToEl($("#mobile-card"))
      }
    }
    
    // If the tabs are already active, shown.bs.tab won't fire.
    if($("#line-items-tab.active, #mobile-line-items-tab.active").length) {
      scrollToMobileCard()
    }

    // If the tabs aren't already active, this will fire.
    $("#line-items-tab, #mobile-line-items-tab").one("shown.bs.tab", function() {
      scrollToMobileCard()
    }).tab("show")

    this.showSidebar()
  } 

  get sidebarController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("client-sidebar"), 
      "client-side--sidebar"
    )
  } 
}
