import WaveformData from "waveform-data"
import axios from "axios"
import client from "http_client"
import debounce from "lodash.debounce"
import Decimal from "decimal.js-light"
import EventEmitter from "events"
import throttle from "lodash.throttle"
import { ResizeObserver } from "@juggle/resize-observer"
import { hhMMSS } from "utils/time"

window.touchHandled = false

/*
 * Events:
 *
 * data:load - Once waveform data has been loaded and initialized
 * waveform:drawn - The first time the waveform has successfully drawn
 * waveform:click - Anytime the waveform is clicked anywhere except on a comment
 * comment:click - Anytime a comment is clicked
 * comment:update - Anytime a comment's range is updated/removed
 * zoom:update - Anytime zoom has successfully been updated
 */

export default class Waveform extends EventEmitter {
  constructor(opts) {
    super()

    this.canvasSize = 2000
    this.container = opts.container
    this.dataUrl = opts.dataUrl
    this.duration = opts.duration
    this.waveformData = null
    this.waveformDataType = null // "peakarray" or "audiowaveform"
    this.containerHeight = opts.containerHeight || 100
    this.containerSidePadding = 20
    this.comments = {}
    this.hideTimeline = opts.hideTimeline == true
    this.progressColor = opts.progressColor || "#32325d"
    this.progressWave = null
    this.progress = opts.progress || 0
    this.disableRangeAdjustments = opts.disableRangeAdjustments === true
    this.waveformColor = opts.waveformColor || "#cad1d7"
    this.zoom = opts.zoom ? parseFloat(opts.zoom) : 1.0
    this.zoomSteps = opts.zoomSteps || 3 // Minimum is 1 (no zoom allowed)
    
    this._axiosSource = null // Used for canceling draw data request
    this._canZoom = false // Used to restrict zoom -- CANNOT ZOOM WHILE DRAWING, this will be false
    this._maxZoom = 1
    this._currentHandleBeingDragged = null // Used for dragging
    this._startingClientX = null // Used for dragging
    this._originalTimeStart = null // Used for dragging
    this._originalTimeEnd = null // Used for dragging
    this._touchMoved = null // Used for dragging on touch devices
    this._followProgress = opts.followProgress || false
    this._resizeObserver = new ResizeObserver(this._handleResize)
    this._waveWrapper = null
    this._zoomedInWaveformData = null
    this._zoomedOutWaveformData = null
  }

  addComment = ({
    id,
    addressed,
    authorInitials,
    timeEnd,
    timeStart,
  }) => {
    this.comments[id] = {
      beingDragged: false,
      id,
      addressed,
      authorInitials,
      timeEnd: timeEnd == "" ? null : timeEnd,
      timeStart,
    }

    if(this._waveWrapper) {
      this._drawCommentMarker(id)
    }
  }

  get canZoomIn() {
    return !!(this.waveformData && this.waveformDataType === "audiowaveform" && this._canZoom && this.zoom < this._maxZoom)
  }

  get canZoomOut() {
    return !!(this.waveformData && this.waveformDataType === "audiowaveform" && this._canZoom && this.zoom > 1.1)
  }

  destroy = () => {
    this._resizeObserver.disconnect()
    this.container && this.container.removeEventListener("scroll", this._handleScroll)
    this._waveWrapper && this._waveWrapper.remove()
    this._axiosSource && this._axiosSource.cancel()
  }

  get dpr() {
    return window.devicePixelRatio || 1
  }

  draw = () => {
    const self = this

    if(self.zoom <= 1.1) {
      self.container.style.padding = `${this._containerTopPaddingSansScrollbar}px ${this.containerSidePadding}px ${this._containerBottomPaddingSansScrollbar}px ${this.containerSidePadding}px`
    } else {
      self.container.style.padding = `${this._containerTopPaddingWithScrollbar}px ${this.containerSidePadding}px ${this._containerBottomPaddingWithScrollbar}px ${this.containerSidePadding}px`
    }

    self.container.addEventListener("scroll", this._handleScroll)
    self._canZoom = false
    const source = axios.CancelToken.source()
    self._axiosSource = source

    client.get(self.dataUrl, {
      cancelToken: self._axiosSource.token
    })
    .then(async (response) => {
      const json = response.data

      if(json["version"] == null) {
        self.waveformData = json
        self.waveformDataType = "peakarray"
      } else {
        self._zoomedInWaveformData = WaveformData.create(json)

        try {
          self._zoomedOutWaveformData = self._zoomedInWaveformData.resample({ width: Math.ceil(self._totalCanvasWidthSansDpi) })
        } 
        catch {
          // This only happens if it can't be resampled as low as requested, a.k.a. this is a very short file.
          self._zoomedOutWaveformData = self._zoomedInWaveformData
        }

        const totalCanvasWidthSansZoom = new Decimal(self._containerWidthSansPadding).times(self.dpr)
        const maxZoom = self._zoomedInWaveformData ? new Decimal(self._zoomedInWaveformData.length).dividedBy(totalCanvasWidthSansZoom).toNumber() : 1 
        self._maxZoom = maxZoom < 1 ? 1 : maxZoom
        self.waveformData = self._zoomedOutWaveformData
        self.waveformDataType = "audiowaveform"
      }

      self.emit("data:load")  

      self._waveWrapper = document.createElement("div")

      const isTouchDevice = "ontouchend" in document
      if(isTouchDevice) {
        self._waveWrapper.addEventListener("touchstart", self._handleTouchstart)
        self._waveWrapper.addEventListener("touchmove", self._handleTouchmove)
        self._waveWrapper.addEventListener("touchend", self._handleTouchend)
      }

      self._waveWrapper.addEventListener("mousedown", self._handleMousedown)
      self._waveWrapper.addEventListener("mousemove", self._handleMousemove)
      self._waveWrapper.addEventListener("mouseup", self._handleMouseup)
      self._waveWrapper.addEventListener("mouseleave", self._handleMouseleave)

      self._waveWrapper.style.lineHeight = 0
      self._waveWrapper.style.whiteSpace = "nowrap"
      self._waveWrapper.style.height = `${self.containerHeight + self._waveformWrapperBottomPadding}px`
      self._waveWrapper.style.cursor = "pointer"
      self._waveWrapper.style.userSelect = "none"
      self._waveWrapper.style.padding = `0 0 ${self._waveformWrapperBottomPadding}px 0`
      self.container.appendChild(self._waveWrapper)

      self._drawTimeline()
      self._drawComments()

      self._resizeObserver.observe(self.container)

      self._refreshWave(false)
      self._canZoom = true
      self.emit("waveform:drawn")
    })
  }

  isFollowingProgress() {
    return this._followProgress
  }

  followProgress = () => {
    this._followProgress = true
  }

  removeComment = id => {
    const commentMarkers = document.querySelectorAll(`.comment-marker-${id}`)

    if(commentMarkers.length) {
      commentMarkers.forEach(el => el.remove())
    }

    delete this.comments[id]
  }

  unfollowProgress = () => {
    this._followProgress = false
  }

  // progress should be between 0 and 1
  updateProgress = (progress) => {
    this.progress = progress

    if(this._waveWrapper) {
      if(this._followProgress && this.zoom > 1.1) {
        const newScrollLeft = this.progress * this._totalCanvasWidthSansDpi - (this._containerWidthSansPadding / 2)
        const maxScrollLeft = (this.container.scrollWidth - this.container.clientWidth)

        // If the scrollbar is at the beginning or end, update progress manually
        if(
          (this.container.scrollLeft === maxScrollLeft && newScrollLeft >= this.container.scrollLeft) ||
          (this.container.scrollLeft === 0 && newScrollLeft <= 0)
        ) {
          this._updateProgressInView()
        } else {
          // Otherwise, set the scroll position and let it update progress
          this.container.scrollLeft = newScrollLeft
        }
      } else {
        // If this isn't zoomed in or progress is not tracking scroll, update progress manually
        this._updateProgressInView()
      }
    }
  }

  zoomIn = async () => {
    if(this.canZoomIn) {
      this._canZoom = false
      const formerScrollLeft = this.container.scrollLeft
      const formerScrollWidth = this._containerScrollWidthSansPadding
      const formerZoom = this.zoom

      this.zoom = new Decimal(this.zoom).plus(this._zoomStep).toNumber()
      this.waveformData = this._zoomedInWaveformData
      let newScrollLeft

      if(formerZoom <= 1.1) {
        newScrollLeft = this.progress * this._totalCanvasWidthSansDpi - (this._containerWidthSansPadding / 2)
      } else {
        const scrollLeftPct = formerScrollLeft / formerScrollWidth
        const zoomDelta = new Decimal(this.zoom).dividedBy(formerZoom).toNumber()
        newScrollLeft = scrollLeftPct * this._totalCanvasWidthSansDpi - (this._containerWidthSansPadding / 2) + ((this._containerWidthSansPadding * zoomDelta) / 2)
      }

      if(newScrollLeft < 0) {
        newScrollLeft = 0
      }

      if(this.zoom <= 1.1) {
        this.container.style.padding = `${this._containerTopPaddingSansScrollbar}px ${this.containerSidePadding}px ${this._containerBottomPaddingSansScrollbar}px ${this.containerSidePadding}px`
      } else {
        this.container.style.padding = `${this._containerTopPaddingWithScrollbar}px ${this.containerSidePadding}px ${this._containerBottomPaddingWithScrollbar}px ${this.containerSidePadding}px`
      }

      this._drawTimeline()
      this._drawComments()
      this._refreshWave(true)
      this.container.scrollLeft = newScrollLeft


      this.container.style.overflowX = "scroll"
      this._canZoom = true
      this.emit("zoom:update") 
    }
  }

  zoomOut = async () => {
    if(this.canZoomOut) {
      this._canZoom = false
      const formerScrollLeft = this.container.scrollLeft
      const formerScrollWidth = this._containerScrollWidthSansPadding
      const formerZoom = this.zoom
      const scrollLeftPct = formerScrollLeft / formerScrollWidth

      this.zoom = new Decimal(this.zoom).minus(this._zoomStep).toNumber()
      let newScrollLeft

      if(this.zoom > 1.1) {
        this.waveformData = this._zoomedInWaveformData
        const zoomDelta = new Decimal(this.zoom).dividedBy(formerZoom).toNumber()
        newScrollLeft = scrollLeftPct * this._totalCanvasWidthSansDpi - (this._containerWidthSansPadding / 2) + ((this._containerWidthSansPadding * zoomDelta) / 2)
      } else {
        this.waveformData = this._zoomedOutWaveformData
        newScrollLeft = 0
      }

      if(newScrollLeft < 0) {
        newScrollLeft = 0
      }

      if(this.zoom <= 1.1) {
        this.container.style.padding = `${this._containerTopPaddingSansScrollbar}px ${this.containerSidePadding}px ${this._containerBottomPaddingSansScrollbar}px ${this.containerSidePadding}px`
      } else {
        this.container.style.padding = `${this._containerTopPaddingWithScrollbar}px ${this.containerSidePadding}px ${this._containerBottomPaddingWithScrollbar}px ${this.containerSidePadding}px`
      }

      this._drawTimeline()
      this._drawComments()
      this._refreshWave(true)
      this.container.scrollLeft = newScrollLeft


      if(this.zoom > 1.1) {
        this.container.style.overflowX = "scroll"
      } else {
        this.container.style.overflowX = "hidden"
      }

      this._canZoom = true
      this.emit("zoom:update")
    } else {
      this.container.style.overflowX = "hidden"
    }
  }

  // PRIVATE

  /* 
   * Getters
   */
  get _canvasHeight() {
    return this.containerHeight * this.dpr
  }

  get _containerXPos() { 
    return this.container.getBoundingClientRect().left + this.containerSidePadding - this.container.scrollLeft
  }

  get _containerTopPaddingSansScrollbar() {
    return 20
  }

  get _containerBottomPaddingSansScrollbar() {
    return 20
  }

  get _containerTopPaddingWithScrollbar() {
    return 20
  }

  get _containerBottomPaddingWithScrollbar() {
    return this.hideTimeline ? 18 : 20
  }

  get _containerScrollWidthSansPadding() {
    // scrollWidth only adds the left padding
    return this.container.scrollWidth - this.containerSidePadding
  }

  get _containerWidthSansPadding() {
    return this.container.offsetWidth - (this.containerSidePadding * 2)
  }

  get _numOfCanvases() {
    return Math.ceil(this._totalCanvasWidth / this.canvasSize)
  }

  get _numOfCanvasesInView() {
    return Math.ceil(this._containerWidthSansPadding / (this.canvasSize / this.dpr)) + 2
  }

  get _peaksPerPixelDecimal() {
    return new Decimal(this.waveformData.length).dividedBy(this._totalCanvasWidth)
  }

  get _pixelsPerPeak() {
    return this._totalCanvasWidth / this.waveformData.length
  }

  get _progressPct() {
    return this.progress * 100
  }

  get _totalCanvasWidth() {
    return this._containerWidthSansPadding * this.dpr * this.zoom
  }

  get _totalCanvasWidthSansDpi() {
    return this._containerWidthSansPadding * this.zoom
  }

  get _waveformWrapperBottomPadding() {
    return this.hideTimeline ? 0 : 10
  }

  get _waveformWrapperMarginSansScrollbar() {
    return `${this._waveformWrapperBottomPadding / 2}px 0 -${this._waveformWrapperBottomPadding / 2}px 0`
  }

  get _waveformWrapperMarginWithScrollbar() {
    const scrollbarOffset = this.hideTimeline ? 0 : 2 // 2 is the height of the scrollbar as set by CSS; sans timeline waveforms still have the scrollbar but the spacing works out differently for some reason
    return `${this._waveformWrapperBottomPadding / 2}px 0 -${(this._waveformWrapperBottomPadding / 2) + scrollbarOffset}px 0`
  }

  get _zoomStep() {
    const maxZoom = new Decimal(this._maxZoom)
    return maxZoom.gte(this.zoomSteps) ? maxZoom.minus(1).dividedBy(this.zoomSteps).toNumber() : 1
  }

  /* 
   * Methods
   */
  _calculateCanvasIndexAtPos(pos) {
    return Math.floor(pos / (this.canvasSize / this.dpr))
  }

  _calculateFirstCanvasIndexInView() {
    const currentCanvasIndex = this._calculateCanvasIndexAtPos(this.container.scrollLeft)
    return currentCanvasIndex === 0 ? 0 : currentCanvasIndex - 1
  }

  _calculateLastCanvasIndexInView() {
    const firstCanvasIndex = this._calculateFirstCanvasIndexInView(this.container.scrollLeft)
    const lastCanvasIndex = firstCanvasIndex + this._numOfCanvasesInView - 1
    return lastCanvasIndex > (this._numOfCanvases - 1) ? (this._numOfCanvases - 1) : lastCanvasIndex
  }

  _calculateCanvasProgress = index => {
    const progressInPixels = this.progress * (this._totalCanvasWidth / this.dpr)
    const canvasSizeSansDpi = this.canvasSize / this.dpr
    const canvasProgressStart = index * canvasSizeSansDpi
    if(progressInPixels > canvasProgressStart) {
      const progressRemaining = progressInPixels - canvasProgressStart
      if(progressRemaining > canvasSizeSansDpi) {
        return canvasSizeSansDpi
      } else {
        return progressRemaining
      }
    } else {
      return 0
    }
  }

  _drawPeakArrayBarCanvas = (forceRedraw) => {
    const gutter = this._pixelsPerPeak * (this._totalCanvasWidth / this.dpr > 600 ? 0.5 : 0.1)

    const existingCanvasContainer = document.getElementById(`waveform-section-0`)
    if(existingCanvasContainer && !forceRedraw) {
      this._updateCanvasProgress(0)
    } else {
      if(existingCanvasContainer) {
        existingCanvasContainer.remove()
      }

      const canvasContainer = document.createElement("div")
      canvasContainer.id = "waveform-section-0"
      canvasContainer.style.width = `${this._totalCanvasWidth / this.dpr}px`
      canvasContainer.style.height = `${this.containerHeight}px`
      canvasContainer.style.display = "block"
      canvasContainer.style.position = "absolute"
      canvasContainer.style.top = 0
      canvasContainer.style.left = 0

      const createCanvas = color => {
        const canvas = document.createElement("canvas")
        canvas.style.display = "inline-block"
        canvas.width = this._totalCanvasWidth
        canvas.height = this._canvasHeight
        canvas.style.width = `${canvas.width / this.dpr}px`
        canvas.style.height = `${this.containerHeight}px`

        const ctx = canvas.getContext("2d")
        ctx.beginPath()
        ctx.fillStyle = color

        const max = Math.max(...this.waveformData.map(x => Math.abs(x)))

        // Amplitude is between 0 and 1, so multiplying it by canvasHeight should offer a fairly accurate
        // representation of the peak
        //
        // Also, normalize if max is greater than 1
        //
        // See http://answers.unity.com/answers/275649/view.html for normalization function
        const scaleY = max > 1 ? 
          (amplitude) => this._canvasHeight * (amplitude / max) :
          (amplitude) => this._canvasHeight * amplitude

        for(let i = 0; i < this.waveformData.length; i++) {
          let val = Math.abs(this.waveformData[i])
          val = val >= 0.015 ? val : 0.015 // At least show a small rectangle if it's silent
          const peakHeight = scaleY(val)
          ctx.fillRect(i * this._pixelsPerPeak, (canvas.height - peakHeight) / 2, this._pixelsPerPeak - gutter, peakHeight)
        }

        ctx.closePath()

        return canvas
      }

      const mainCanvas = createCanvas(this.waveformColor)
      const progressCanvas = createCanvas(this.progressColor)

      canvasContainer.appendChild(mainCanvas)

      const progressCanvasWrapper = document.createElement("div")
      progressCanvasWrapper.id = `waveform-section-0-progress`
      progressCanvasWrapper.style.position = "absolute"
      progressCanvasWrapper.style.top = 0
      progressCanvasWrapper.style.left = 0
      progressCanvasWrapper.style.overflowX = "hidden"
      progressCanvasWrapper.style.height = `${this.containerHeight}px`
      progressCanvasWrapper.style.width = `${this._calculateCanvasProgress(0)}px`
      progressCanvasWrapper.appendChild(progressCanvas)
      canvasContainer.appendChild(progressCanvasWrapper)

      this._waveWrapper.appendChild(canvasContainer)
    }
  }

  _drawAudioWaveformPeakCanvas = (i, forceRedraw) => {
    const channel = this.waveformData.channel(0)

    const scaleX = (peakNum) => this._pixelsPerPeak * peakNum
    const scaleY = (amplitude) => this._canvasHeight - ((amplitude + 128) * this._canvasHeight) / 256

    // If this canvas has already been drawn at the current zoom level, just update progress
    const existingCanvasContainer = document.getElementById(`waveform-section-${i}`)
    if(existingCanvasContainer && existingCanvasContainer.getAttribute("data-zoom") == this.zoom.toString() && !forceRedraw) {
      this._updateCanvasProgress(i)
    } else {
      // If this waveform section exists but was drawn at a different zoom level, remove it
      if(existingCanvasContainer) {
        existingCanvasContainer.remove()
      }

      const width = i === this._numOfCanvases - 1 ? this._totalCanvasWidth % this.canvasSize : this.canvasSize

      const canvasContainer = document.createElement("div")
      canvasContainer.id = `waveform-section-${i}`
      canvasContainer.className = "waveform-section"
      canvasContainer.setAttribute("data-index", i)
      canvasContainer.setAttribute("data-zoom", this.zoom)
      canvasContainer.style.width = `${width / this.dpr}px`
      canvasContainer.style.height = `${this.containerHeight}px`
      canvasContainer.style.display = "block"
      canvasContainer.style.position = "absolute"
      canvasContainer.style.top = 0
      canvasContainer.style.left = `${i * (this.canvasSize / this.dpr)}px`

      const createCanvas = color => {
        const canvas = document.createElement("canvas")
        canvas.style.display = "inline-block"
        canvas.width = width
        canvas.height = this._canvasHeight
        canvas.style.width = `${canvas.width / this.dpr}px`
        canvas.style.height = `${this.containerHeight}px`
        canvas.style.position = "absolute"
        canvas.style.top = 0
        canvas.style.left = 0

        const ctx = canvas.getContext("2d")
        ctx.beginPath()

        // Loop forwards, drawing the upper half of the waveform
        const peaksInCurrentCanvas = this._peaksPerPixelDecimal.times(canvas.width)
        const maxPeaksPerCanvas = this._peaksPerPixelDecimal.times(this.canvasSize)
        const firstPeakInCurrentCanvas = maxPeaksPerCanvas.times(i)
        let lastPeakInCurrentCanvas = firstPeakInCurrentCanvas.plus(peaksInCurrentCanvas)

        // If this is not the last canvas, draw the first peak of the next canvas at the end of this
        // canvas to keep gaps from happening
        let lastPeakToDraw = (i + 1) < this._numOfCanvases ? Math.ceil(lastPeakInCurrentCanvas.toNumber()) + 1 : Math.ceil(lastPeakInCurrentCanvas.toNumber())
        const firstPeakToDraw = Math.floor(firstPeakInCurrentCanvas.toNumber())

        for(let x = firstPeakToDraw; x < lastPeakToDraw; x++) {
          const val = channel.max_sample(x)
          ctx.lineTo(scaleX(x - firstPeakToDraw), scaleY(val))
        }

        // Loop backwards, drawing the lower half of the waveform
        for(let x = lastPeakToDraw - 1; x >= firstPeakToDraw; x--) {
          const val = channel.min_sample(x)
          ctx.lineTo(scaleX(x - firstPeakToDraw), scaleY(val))
        }

        ctx.closePath()
        ctx.strokeStyle = color
        ctx.stroke()
        ctx.fillStyle = color
        ctx.fill()

        return canvas
      }

      const mainCanvas = createCanvas(this.waveformColor)
      const progressCanvas = createCanvas(this.progressColor)

      canvasContainer.appendChild(mainCanvas)

      const progressCanvasWrapper = document.createElement("div")
      progressCanvasWrapper.id = `waveform-section-${i}-progress`
      progressCanvasWrapper.style.position = "absolute"
      progressCanvasWrapper.style.top = 0
      progressCanvasWrapper.style.left = 0
      progressCanvasWrapper.style.overflowX = "hidden"
      progressCanvasWrapper.style.height = `${this.containerHeight}px`
      progressCanvasWrapper.style.width = `${this._calculateCanvasProgress(i)}px`
      progressCanvasWrapper.style.zIndex = 1
      progressCanvasWrapper.appendChild(progressCanvas)
      canvasContainer.appendChild(progressCanvasWrapper)

      this._waveWrapper.appendChild(canvasContainer)
    }
  }

  _drawCommentMarker = (id) => {
    const comment = this.comments[id]
    const timeStartAsPct = this._timeAsZeroToOneHundredPct(comment.timeStart)
    const timeEndAsPct = this._timeAsZeroToOneHundredPct(comment.timeEnd)

    let width = 0

    if(timeEndAsPct != null) {
      width = `${timeEndAsPct - timeStartAsPct}%`
    }

    const commentMarkerId = `comment-marker-${comment.id}`
    const newCommentMarker = document.createElement("div")
    newCommentMarker.className = `waveform-marker ${commentMarkerId} comment-marker ${comment.addressed ? 'hidden' : ''}`
    newCommentMarker.setAttribute("data-controller", "comments--scroll")
    newCommentMarker.setAttribute("data-target", "comments--scroll.marker")
    newCommentMarker.setAttribute("data-comments--scroll-id", comment.id)
    newCommentMarker.setAttribute("data-id", comment.id)
    newCommentMarker.style.top = 0
    newCommentMarker.style.left = `${timeStartAsPct}%`
    newCommentMarker.style.width = width
    newCommentMarker.style.height = `${this.containerHeight}px`
    newCommentMarker.innerHTML = `
      <div class="marker-start">
        ${this.disableRangeAdjustments ? "" :
          `
          <div class="handle">
            <i class="fas fa-sort"></i>
          </div>
          `
        }
      </div>
      <div class="marker-end">
        ${this.disableRangeAdjustments ? "" :
          `
          <div class="handle">
            <i class="fas fa-sort"></i>
          </div>
          `
        }
      </div>
      <div 
        class="marker-dot"
        data-action="click->comments--scroll#scrollToComment"
        title="Click to view comment or drag to create a range"
      >
        ${comment.authorInitials}
      </div>
      ${timeEndAsPct == null ? "" :
        `
        <div class="delete-range" data-id="${comment.id}">
          &times;
        </div>
        `
      }
    `

    let commentMarkers = document.querySelectorAll(`.${commentMarkerId}`)
    if(commentMarkers.length) {
      commentMarkers.forEach(commentMarker => {
        commentMarker.outerHTML = newCommentMarker.outerHTML
      })
    } else {
      this._waveWrapper.appendChild(newCommentMarker)
    }
  }

  _drawComments = () => {
    Object.keys(this.comments).forEach(id => this._drawCommentMarker(id))
  }

  _drawTimeline = () => {
    if(this.hideTimeline) {
      return
    }

    const pixelsPerSecond = this._totalCanvasWidthSansDpi / this.duration
    const tickWidth = this.duration >= 3600 ? 50 : 35
    const gutterWidth = this.zoom <= 1.1 ? 30 : 10
    const totalTickWidth = tickWidth + gutterWidth

    let timeline = this._waveWrapper.querySelector(".waveform-timeline")
    if(timeline != null) {
      timeline.remove()
    }
    timeline = document.createElement("div")
    timeline.className = "waveform-timeline"
    timeline.position = "absolute"
    timeline.left = 0
    timeline.bottom = 0

    // The first second (00:00) should have a tick
    let pixelsSinceLastTick = new Decimal(totalTickWidth).minus(pixelsPerSecond)

    for (let i = 0; i < this.duration; i++) {
      pixelsSinceLastTick = pixelsSinceLastTick.plus(pixelsPerSecond)

      if(pixelsSinceLastTick.lt(totalTickWidth)) {
        continue
      } else {
        pixelsSinceLastTick = new Decimal(0)
      }

      const div = document.createElement("div")
      div.style.position = "absolute"
      div.style.bottom = "-4px"
      div.style.left = `${(i * pixelsPerSecond) - (tickWidth / 2)}px`
      div.style.width = `${tickWidth}px`
      div.style.fontSize = "10px"
      div.style.textAlign = "center"
      div.style.pointerEvents = "none"
      div.style.margin = 0
      div.innerHTML = hhMMSS(i, this.duration)

      const tick = document.createElement("div")
      tick.style.width = "5px"
      tick.style.height = "1px"
      tick.style.transform = "rotate(90deg)"
      tick.style.backgroundColor = "#525f7f"
      tick.style.margin = 0
      tick.style.position = "absolute"
      tick.style.top = "-10px"
      tick.style.left = `${(tickWidth / 2) - 2.5}px`
      div.appendChild(tick)

      timeline.appendChild(div)
    }

    this._waveWrapper.appendChild(timeline)
  }

  _drawWave = (forceRedraw) => {
    if(this.waveformDataType === "audiowaveform") {
      // Create the inner canvases
      const firstCanvasIndex = this._calculateFirstCanvasIndexInView()
      const lastCanvasIndex = this._calculateLastCanvasIndexInView()
      let indices = []
      for(let i = firstCanvasIndex; i <= lastCanvasIndex; i++) {
        indices.push(i)
        this._drawAudioWaveformPeakCanvas(i, forceRedraw)
      }
      this._waveWrapper.querySelectorAll(".waveform-section").forEach(section => {
        if(!indices.includes(parseInt(section.getAttribute("data-index")))) {
          section.remove()
        }
      })
    } else { // this.waveformDataType === "peakarray"
      // Create the legacy bar waveform view
      this._drawPeakArrayBarCanvas(forceRedraw)
    }
  }

  _handleClick = e => {
    const left = this._containerXPos

    if(e.target.className.includes("marker-dot")) {
      const middleOfDot = e.target.getBoundingClientRect().left + (e.target.offsetWidth / 2)
      const posPct = new Decimal(middleOfDot).minus(left).dividedBy(this.zoom).dividedBy(this._containerWidthSansPadding).toNumber()
      e.data = { posPct: posPct }

      this.emit("comment:click", e)
    } else if(e.target.className.includes("delete-range")) {
      const id = e.target.getAttribute("data-id")
      e.target.remove()
      const comment = this.comments[id]
      comment.timeEnd = null
      this.emit("comment:update", { 
        timeStart: comment.timeStart,
        timeEnd: null,
        id, 
      })
    } else {
      const posPct = new Decimal(e.clientX).minus(left).dividedBy(this.zoom).dividedBy(this._containerWidthSansPadding).toNumber()
      e.data = { posPct: posPct }

      this.emit("waveform:click", e)
    }
  }

  _handleMousedown = e => {
    if(e.target.className.includes("handle") && !this.disableRangeAdjustments) {
      const id = e.target.parentElement.parentElement.getAttribute("data-id")
      const comment = this.comments[id]
      comment.beingDragged = true
      this._currentHandleBeingDragged = e.target.parentElement.className.includes("marker-start") ? "start" : "end"
      this._startingClientX = e.clientX
      this._originalTimeStart = comment.timeStart
      this._originalTimeEnd = comment.timeEnd
    }
  }

  _handleMouseleave = e => {
    const id = Object.keys(this.comments).filter(id => this.comments[id].beingDragged)[0]

    if(id) {
      this._handleMouseup(e)
    }
  }

  _handleMousemove = e => {
    const id = Object.keys(this.comments).filter(id => this.comments[id].beingDragged)[0]

    if(id != null) {
      const comment = this.comments[id]
      const left = this._containerXPos
      const zoomedWidth = new Decimal(this.zoom).times(this._containerWidthSansPadding).toNumber()
      const time = new Decimal(e.clientX).minus(left).dividedBy(zoomedWidth).times(this.duration).toNumber()
      let timeStart, timeEnd

      if(this._currentHandleBeingDragged == "start") {
        timeStart = time
        timeEnd = this._originalTimeEnd
      } else {
        timeStart = this._originalTimeStart
        timeEnd = time
      }

      if(timeStart > timeEnd) {
        const tempTimeEnd = timeEnd
        timeEnd = timeStart
        timeStart = tempTimeEnd
      }

      comment.timeStart = timeStart
      comment.timeEnd = timeEnd

      const timeStartAsPct = this._timeAsZeroToOneHundredPct(timeStart)
      const timeEndAsPct = this._timeAsZeroToOneHundredPct(timeEnd)

      const commentMarkers = document.querySelectorAll(`.comment-marker-${id}`)

      if(commentMarkers.length) {
        commentMarkers.forEach(commentMarker => {
          commentMarker.style.left = `${timeStartAsPct}%`
          commentMarker.style.width = timeEndAsPct ? `${timeEndAsPct - timeStartAsPct}%` : 0
        })
      }
    }
  }

  _handleMouseup = e => {
    const id = Object.keys(this.comments).filter(id => this.comments[id].beingDragged)[0]

    if(id) {
      const comment = this.comments[id]
      comment.beingDragged = false
      const dragThreshold = 1

      const commentMarkers = document.querySelectorAll(`.comment-marker-${id}`)

      // If the user dragged more than the threshold, count it as a drag. Otherwise it's a click.
      if(this._startingClientX - dragThreshold > e.clientX || this._startingClientX + dragThreshold < e.clientX) {
        if(commentMarkers.length) {
          commentMarkers.forEach(commentMarker => {
            if(commentMarker.querySelector(".delete-range") == null) {
              const deleteRange = document.createElement("div")
              deleteRange.className = "delete-range"
              deleteRange.setAttribute("data-id", comment.id)
              deleteRange.innerHTML = "&times;"
              commentMarker.appendChild(deleteRange)
            }
          })
        }

        this.emit("comment:update", { 
          timeStart: comment.timeStart,
          timeEnd: comment.timeEnd,
          id, 
        })
      } else {
        // Reset the comment marker in case it was dragged
        const timeStartAsPct = this._timeAsZeroToOneHundredPct(this._originalTimeStart)
        const timeEndAsPct = this._timeAsZeroToOneHundredPct(this._originalTimeEnd)

        if(commentMarkers.length) {
          commentMarkers.forEach(commentMarker => {
            commentMarker.style.left = `${timeStartAsPct}%`
            commentMarker.style.width = timeEndAsPct ? `${timeEndAsPct - timeStartAsPct}%` : 0
          })
        }

        comment.timeStart = this._originalTimeStart
        comment.timeEnd = this._originalTimeEnd

        this._handleClick(e)
      }

      this._startingClientX = null
      this._currentHandleBeingDragged = null
    } else {
      this._handleClick(e)
    }
  }

  _handleResize = debounce(() => {
    this._canZoom = false
    this._drawTimeline()
    this._drawComments()
    this._refreshWave(true)
    this._canZoom = true
  }, 200)

  _handleScroll = throttle(() => {
    this._waveWrapper && this._refreshWave(false)
  }, 15) // ~60fps

  _handleTouchend = e => {

    // Ignore event if not handled
    if(!window.touchHandled) {
      return
    }

    // Simulate the mouseup event
    this._simulateMouseEvent(e, "mouseup")

    // Simulate the mouseout event
    this._simulateMouseEvent(e, "mouseout")

    // If the touch interaction did not move, it should trigger a click
    if (!this._touchMoved) {

      // Simulate the click event
      this._simulateMouseEvent(e, "click")
    }

    // Unset the flag to allow other widgets to inherit the touch event
    window.touchHandled = false
  }

  _handleTouchmove = e => {
    
    // Ignore event if not handled
    if(!window.touchHandled) {
      return
    }

    // Interaction was not a click
    this._touchMoved = true

    // Simulate the mousemove event
    this._simulateMouseEvent(e, "mousemove")
  }

  _handleTouchstart = e => {

    // Ignore the event if another widget is already being handled
    if (window.touchHandled || !e.changedTouches[0]) {
      return
    }

    // Set the flag to prevent other widgets from inheriting the touch event
    window.touchHandled = true

    // Track movement to determine if interaction was a click
    this._touchMoved = false

    // Simulate the mouseover event
    this._simulateMouseEvent(e, "mouseover")

    // Simulate the mousemove event
    this._simulateMouseEvent(e, "mousemove")

    // Simulate the mousedown event
    this._simulateMouseEvent(e, "mousedown")
  }

  _refreshWave = (forceRedraw) => {
    if(this.zoom <= 1.1) {
      this.container.style.overflowX = "hidden"
    }

    // Update width of wrapper
    this._waveWrapper.style.width = `${this._totalCanvasWidthSansDpi}px`
    this._waveWrapper.style.position = "relative"

    // Create waves
    this._drawWave(forceRedraw)

    this.container.classList.add("waveform")

    if(this.zoom > 1.1) {
      this.container.style.margin = this._waveformWrapperMarginWithScrollbar
    } else {
      this.container.style.margin = this._waveformWrapperMarginSansScrollbar
    }
  }

  _simulateMouseEvent(e, simulatedType) {

    // Ignore multi-touch events
    if(e.touches.length > 1) {
      return;
    }

    // If this is one of the draggable elements, don't prevent default so that scroll is possible.
    if(
      e.target.className.includes("marker-start") ||
      e.target.className.includes("marker-end") ||
      e.target.className.includes("handle")
    ) {
      e.preventDefault();
    }

    var touch = e.changedTouches[0],
        simulatedEvent = document.createEvent("MouseEvents");
    
    // Initialize the simulated mouse event using the touch event's coordinates
    simulatedEvent.initMouseEvent(
      simulatedType,    // type
      true,             // bubbles                    
      true,             // cancelable                 
      window,           // view                       
      1,                // detail                     
      touch.screenX,    // screenX                    
      touch.screenY,    // screenY                    
      touch.clientX,    // clientX                    
      touch.clientY,    // clientY                    
      false,            // ctrlKey                    
      false,            // altKey                     
      false,            // shiftKey                   
      false,            // metaKey                    
      0,                // button                     
      null              // relatedTarget              
    );

    // Dispatch the simulated event to the target element
    e.target.dispatchEvent(simulatedEvent);
  }

  _timeAsZeroToOneHundredPct = time => time != null ? new Decimal(time).dividedBy(this.duration).times(100).toNumber() : null

  _updateCanvasProgress = index => {
    const progressCanvasWrapper = document.getElementById(`waveform-section-${index}-progress`)
    if(progressCanvasWrapper) {
      progressCanvasWrapper.style.width = `${this._calculateCanvasProgress(index)}px`
    }
  }

  _updateProgressInView = () => {
    const firstCanvasIndex = this._calculateFirstCanvasIndexInView()
    const lastCanvasIndex = this._calculateLastCanvasIndexInView()
    for(let i = firstCanvasIndex; i <= lastCanvasIndex; i++) {
      this._updateCanvasProgress(i)
    }
  }
}