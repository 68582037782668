import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "projectLineItemFields" ]

  connect() {
    if(!$(this.checkboxTarget).is(":checked")) {
      $(this.projectLineItemFieldsTarget).hide()
    }
  }

  toggleProjectLineItemFields() {
    $(this.projectLineItemFieldsTarget).toggle()
  }
}