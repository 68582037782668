import ApplicationController from "controllers/application_controller"
import flow from "@prosperstack/flow"

export default class extends ApplicationController {
  initFlow() {
    const cancelSubscriptionUrl = this.data.get("cancelSubscriptionUrl")
    const chargebeeSubscriptionId = this.data.get("chargebeeSubscriptionId")
    const pauseSubscriptionUrl = this.data.get("pauseSubscriptionUrl")
    const tryHobbyistSubscriptionUrl = this.data.get("tryHobbyistSubscriptionUrl")
    const testMode = this.data.get("testMode") === "true" ? true : false

    const executeOffer = url => {
      $.ajax({
        type: "POST",
        url: url,
        headers: {
          "X-CSRF-Token": Rails.csrfToken()
        },
        complete: () => {
          window.location.reload()
        }
      })
    }

    flow({
      flowId: "JHVkOJW7OGuLBqRUvaN4o",
      subscription: {
        paymentProviderId: chargebeeSubscriptionId
      },
      testMode,
    }).then((result) => {
      switch (result.status) {
        case "canceled": {
          executeOffer(cancelSubscriptionUrl)
          break          
        }

        case "saved": {
          switch (result.offer.id) {
            case "Q8fuL81QeKSaoffuzOyC_":
            case "Qv-x_QZ_KOC8C90xsYPzq": {
              executeOffer(pauseSubscriptionUrl)
              break
            }
            case "fcX-PVxTLieFKJifqxUHX": {
              executeOffer(tryHobbyistSubscriptionUrl)
              break
            }
            case "l2TBuM7Fv16VGtJjoP3x2": {
              Intercom("showNewMessage")
            }
          }

          break
        }
        case "incomplete": {
          break
        }
      }
    })
  }
}
