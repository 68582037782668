import ApplicationController from "controllers/application_controller"
import debounce from "lodash.debounce"
import hotkeys from "hotkeys-js"

export default class extends ApplicationController {
  static targets = [ "form", "input", "modal", "results", "select", "spinner", "typeOption" ]

  initialize() {
    const self = this

    self._executeQuery = debounce(self._executeQuery, 250, {
      leading: false,
      trailing: true
    })
  }

  connect() {
    const self = this
    const type = self.data.get("type") || "FileSystemObject"

    $(self.typeOptionTargets).removeClass("active")

    self.typeOptionTargets.forEach(target => {
      if(target.dataset.type === type) {
        self._selectType(target)
      }
    })

    self._executeQueryBound = self._executeQuery.bind(self)
    const $input = $(self.inputTarget)
    $(self.modalTarget).on("show.bs.modal", () => {
      self.data.set("modalOpen", true)
    }).on("shown.bs.modal", () => {
      $input.on("keyup", self._executeQueryBound)
      $input.trigger("focus")

      self.data.set("modalShown", true)
    }).on("hide.bs.modal", () => {
      self.data.set("modalShown", false)
      $input.off("keyup", self._executeQueryBound)
    }).on("hidden.bs.modal", () => {
      $input.val("")
      $(self.resultsTarget).empty()
      self.data.set("modalOpen", false)
    })

    self._handleTurbolinksBeforeCacheBound = self._handleTurbolinksBeforeCache.bind(self)
    $(document).on("turbolinks:before-cache", self._handleTurbolinksBeforeCacheBound)

    self._toggleModalBound = self._toggleModal.bind(self)
    hotkeys("command+k,ctrl+k", self._toggleModalBound)

    hotkeys.filter = ({ target }) => {
      return target.tagName !== "INPUT" || target === self.inputTarget
    }
  }

  disconnect() {
    const self = this

    $(document).off("turbolinks:before-cache", self._handleTurbolinksBeforeCacheBound)
    hotkeys.unbind("command+k,ctrl+k", self._toggleModalBound)
    $(self.inputTarget).off("keyup", self._executeQueryBound)
  }

  _executeQuery() {
    const self = this
    const q = $(self.inputTarget).val()
    const type = self.data.get("type")
    
    if(q.length < 1) {
      $(self.resultsTarget).empty()
      return
    }

    self.showSpinner()

    $.ajax({
      type: "POST",
      url: self.data.get("url"),
      data: {
        q,
        type,
      },
      headers: {
        "X-CSRF-Token": Rails.csrfToken()
      },
      complete: (response) => {
        $(self.resultsTarget).html(response.responseText)
        self.hideSpinner()
      }
    })
  }

  hideSpinner() {
    const self = this
    
    $(self.spinnerTarget).removeClass("d-block").addClass("d-none")
  }

  _handleTurbolinksBeforeCache() {
    const self = this

    self.data.set("modalOpen", false)
    self.data.set("modalShown", false)
  }

  selectType(e) {
    const self = this

    self._selectType(e.currentTarget)
    $(self.inputTarget).trigger("focus")
    self._executeQuery()
  }

  showSpinner() {
    const self = this

    $(self.spinnerTarget).addClass("d-block").removeClass("d-none")
  }

  _selectType(option) {
    const self = this

    self.data.set("type", option.dataset.type)
    $(self.typeOptionTargets).removeClass("active")
    $(option).addClass("active")

    $(self.resultsTarget).empty()
    $(self.inputTarget).attr("placeholder", option.dataset.inputPlaceholder)
    $(self.selectTarget).html(option.dataset.selectContent)
  }

  _toggleModal(e) {
    e.preventDefault()
    const self = this
    const modalOpen = self.data.get("modalOpen") === "true"

    if(!modalOpen) {
      $(self.modalTarget).modal("show")
    } else {
      $(self.modalTarget).modal("hide")
    }
  }
}