import { Controller } from "stimulus"
import ImageAnnotator from "image_annotator"

export default class extends Controller {
  static targets = [ "annotator", "image", "pageDivider" ]

  connect() {
    const self = this
    const fileSystemObjectId = self.data.get("fileSystemObjectId")
    const page = self.data.get("page")
    const id = page ? `${fileSystemObjectId}-page-${page}` : fileSystemObjectId

    $(self.imageTarget).on("load", function() {
      self.imageAnnotator = new ImageAnnotator({
        container: self.annotatorTarget,
        disableAnnotating: !self.isClientSide,
        enablePhotoswipe: true,
        id: id,
        newCommentPopoverContent: self.data.get("newCommentPopoverContent"),
      })
      self.imageAnnotator.on("marker:add", e => {
        const $form = $(e.popover).find("form")
        const page = self.data.get("page")
        $form.find("[name$='[canvas_object]']").val(e.canvasObject)

        if(page) {
          $form.find("[name$='[page]']").val(page)
        }
      })

      if(self._queuedComments) {
        self._queuedComments.forEach(comment => self.placeCommentMarker(comment))
        self._queuedComments = null
      }

      self.imageAnnotator.draw()

      if(self.hasPageDividerTarget) {
        $(self.pageDividerTarget).addClass("show")
      }
    })
  }

  disconnect() {
    if(this.imageAnnotator) {
      this.imageAnnotator.destroy()
    }
  }

  get isClientSide() {
    return this.data.get("clientSide") === "true"
  }

  // For adding existing markers from a different controller
  placeCommentMarker({
    id, 
    addressed,
    authorName,
    canvasObject,
    popoverContent,
  }) {
    // If the waveform has been created already, add the comments
    if(this.imageAnnotator) {
      this.imageAnnotator.addComment({
        id, 
        addressed,
        authorName,
        canvasObject,
        popoverContent, 
      })
    } else { // Otherwise, queue them up to be added once it's created
      if(this._queuedComments == null) {
        this._queuedComments = []
      }

      this._queuedComments.push({
        id, 
        addressed,
        authorName,
        canvasObject,
        popoverContent, 
      })
    }
  }

  removeCommentMarker(id) {
    this.imageAnnotator.removeComment(id)
  }
}
