import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [ "container" ]

  loadNextPage(e) {
    const self = this
    const loading = self.data.get("loading")
    
    if(loading !== "true") {
      const offset = parseInt(self.data.get("offset")) || 500
      const container = self.hasContainerTarget ? self.containerTarget : self.element

      let scrollTop, innerHeight, height
      if(e.target === document) {
        const $window = $(window)

        scrollTop = $window.scrollTop()
        innerHeight = $(e.target).height()
        height = $window.height()
      } else {
        const $target = $(e.target)

        scrollTop = $target.scrollTop()
        innerHeight = e.target.scrollHeight
        height = $target.height()
      }

      if(scrollTop > innerHeight - height - offset) {
        // TODO: Insert loading visual here
        const url = self.data.get("url")
        const pagesLoaded = parseInt(self.data.get("pagesLoaded")) || 1
        const totalPages = parseInt(self.data.get("totalPages")) || 1
    
        if(pagesLoaded < totalPages) {
          self.data.set("loading", true)
          const separator = url.match(/\?/) ? "&" : "?"
          const nextPageUrl = `${url}${separator}page=${pagesLoaded + 1}`
          const method = self.data.get("method") || "GET"
          self.emit("infinite-list:page-loading")
    
          $.ajax({
            url: nextPageUrl,
            method,
            headers: {
              "X-CSRF-Token": Rails.csrfToken()
            },
            success: function(html) {
              self.data.set("pagesLoaded", pagesLoaded + 1)
              $(container).append(html)
              self.data.set("loading", false)
              self.emit("infinite-list:page-loaded")
            },
          })
        }
      }
    }
  }
}