import { Controller } from "stimulus"

const setError = ($form, msg) => {
  const $errorElement = $form.find(".card-errors")

  $errorElement.html(`<div class="alert alert-danger alert-dismissible mt-0 mb-3">
    ${msg}
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>`)
  $form.find("input[name='stripe_payment_method_id']").val("")
  $form.find("input[name='stripe_payment_intent_id']").val("")
  $form.find("input[name='project_payment[paypal_order_id]']").val("")
  $form.find("input[name='project_payment[payment_type]']").val("")
  enableFormSubmission($form)
}

const disableFormSubmission = ($form) => {
  $form.find(":submit").prop("disabled", true)
  $form.find(".paypal-container").addClass("disabled")
}

const enableFormSubmission = ($form) => {
  $form.find(":submit").removeAttr("disabled")
  $form.find(".paypal-container").removeClass("disabled")
}

export default class extends Controller {
  static targets = [ ]

  connect() {
    if(window.Stripe) {
      this.initStripe()
    }

    if(window.paypal) {
      this.initPayPal()
    }
  }

  initPayPal() {
    const form = this.element
    const $form = $(form)
    const amountInDollars = this.data.get("amountInDollars")
    const merchantId = this.data.get("paypalMerchantId")

    paypal.Buttons({
      style: {
        height: 43
      },
      createOrder: function(data, actions) {
        disableFormSubmission($form)
        $form.find("input[name='project_payment[payment_type]']").val("paypal")

        // This function sets up the details of the transaction, including the amount and line item details.
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: amountInDollars,
            },
            payee: {
              merchant_id: merchantId
            },
          }],
        })
      },
      onApprove: function(data, actions) {
        $form.find("input[name='project_payment[paypal_order_id]']").val(data.orderID)

        $.ajax({
          type: "POST",
          url: $form.attr("action"),
          headers: {
            "X-CSRF-Token": Rails.csrfToken()
          },
          data: $form.serialize(),
          success: function (response) {
            window.location.reload()
          },
          error: function (response) {
            setError($form, response.responseJSON.error)
          }
        })
      },
      onCancel: function(data, actions) {
        enableFormSubmission($form)
      },
      onError: function(err) {
        setError($form, err)
      }
    }).render(`#paypal-${this.data.get("cardFieldId")}`);
  }

  initStripe() {
    const form = this.element
    const $form = $(form)
    const stripe = Stripe(this.data.get("stripePublishableKey"), {
      stripeAccount: this.data.get("stripeAccountId")
    })
    const elements = stripe.elements({
      fonts: [
        {
          cssSrc: "https://fonts.googleapis.com/css?family=Open+Sans",
        },
      ],
      locale: "auto"
    })

    const card = elements.create("card", {
      iconStyle: "solid",
      style: {
        base: {
          color: "#32325d",
          fontFamily: "Open Sans, sans-serif",
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#adb5bd" // $gray-500
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      },
    })
    card.mount(`#${this.data.get("cardFieldId")}`)

    const handleServerResponse = function(response) {
      const $form = $(form)
      if (response.error) {
        // Show error from server on payment form
        setError($form, response.error)
      } else if(response.payment_intent_client_secret) {
        // Use Stripe.js to handle required card action
        stripe.handleCardAction(
          response.payment_intent_client_secret
        ).then(function(result) {
          if (result.error) {
            // Show error in payment form
            setError($form, result.error.message)
          } else {
            // The card action has been handled
            // The PaymentIntent can be confirmed again on the server
            $form.find("input[name='stripe_payment_intent_id']").val(result.paymentIntent.id)
            $.ajax({
              type: "POST",
              url: $form.attr("action"),
              headers: {
                "X-CSRF-Token": Rails.csrfToken()
              },
              data: $form.serialize(),
              success: function (response) {
                handleServerResponse(response)
              },
              error: function (response) {
                setError($form, response.responseJSON.error)
              }
            })
          }
        });
      } else {
        window.location.reload()
      }
    }

    const submitPaymentMethod = function(paymentMethodId) {
      const $form = $(form)
      $form.find("input[name='stripe_payment_method_id']").val(paymentMethodId)
      $form.find("input[name='project_payment[payment_type]']").val("card")

      $.ajax({
        type: "POST",
        url: $form.attr("action"),
        headers: {
          "X-CSRF-Token": Rails.csrfToken()
        },
        data: $form.serialize(),
        success: function (response) {
          handleServerResponse(response)
        },
        error: function (response) {
          setError($form, response.responseJSON.error)
        }
      })
    }

    const handleFormSubmit = function(card) {
      const $form = $(form)
      $form.on("submit", function(e) {
        e.preventDefault()
        disableFormSubmission($form)
        stripe.createPaymentMethod("card", card).then(function(result) {
          if(result.error) {
            setError($form, result.error.message)
          } else {
            submitPaymentMethod(result.paymentMethod.id)
          }
        })
      })

      $form.find(":submit").removeAttr("disabled")
    }

    card.on("ready", function() {
      handleFormSubmit(card)
    })
  }
}
