import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]

  connect() {
    $("#progress-card").addClass("right-sidebar-visible")
  }

  disconnect() {
    $("#progress-card").removeClass("right-sidebar-visible")
  }
}
