import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  openPortal(e) {
    e.preventDefault()
    const createPortalSessionUrl = this.data.get("createPortalSessionUrl")
    const section = this.data.get("section")
    const subscriptionId = this.data.get("subscriptionId")
    const successUrl = this.data.get("successUrl")

    Chargebee.init({ site: $("#chargebee-js").attr("data-cb-site") })
    Chargebee.registerAgain()

    let params
    if(section == "ADDRESS") {
      params = null
    } else {
      params = {
        sectionType: Chargebee.getPortalSections()[section],
        params: {
          subscriptionId: subscriptionId
        }
      }
    }

    const cbInstance = Chargebee.getInstance()
    cbInstance.setPortalSession(function() {
      return $.ajax({
        type: "POST",
        url: createPortalSessionUrl,
        headers: {
          "X-CSRF-Token": Rails.csrfToken()
        }
      })
    })
    const cbPortal = cbInstance.createChargebeePortal()
    let refresh = false
    cbPortal.open({
      paymentSourceAdd() {
        refresh = true
      },
      paymentSourceUpdate() {
        refresh = true
      },
      paymentSourceRemove() {
        refresh = true
      },
      subscriptionChanged() {
        refresh = true
      },
      subscriptionCustomFieldsChanged() {
        refresh = true
      },
      subscriptionCancelled() {
        refresh = true
      },
      subscriptionResumed() {
        refresh = true
      },
      subscriptionPaused() {
        refresh = true
      },
      scheduledPauseRemoved() {
        refresh = true
      },
      subscriptionReactivated() {
        refresh = true
      },
      close() {
        if(refresh) {
          window.location.href = successUrl
        }
      }
    }, params)
  }
}
