import ApplicationController from "controllers/application_controller"
import PhotoSwipe from "photoswipe"
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default"

import "photoswipe/dist/photoswipe.css"
import "photoswipe/dist/default-skin/default-skin.css"

export default class extends ApplicationController {
  static targets = [ "photoswipe" ]

  connect() {
    const self = this
    const imageSrcs = self.data.get("imageUrls").split(",")
    self.items = imageSrcs.map(_ => null)

    imageSrcs.forEach((src, index) => {
      const img = new Image()
      img.src = src
      img.onload = function() {
        const item = {
          src,
          w: this.width,
          h: this.height,
          title: `Page ${index + 1}`
        }

        self.items.splice(index, 1, item)

        if(self.photoswipe) {
          let itemsRemaining = self.items.length
          let continueIterating = true
          while(itemsRemaining > 0 && continueIterating) {
            const index = Math.abs(self.items.length - itemsRemaining)
            const currentItem = self.items[index]

            if(currentItem == null) {
              continueIterating = false
              return
            }

            self.photoswipe.items.splice(index, 1, currentItem)
            itemsRemaining--
          }
          self.photoswipe.invalidateCurrItems()
          self.photoswipe.updateSize(true)
          self.photoswipe.ui.update()
        }
      }
    })
  }

  open(e) {
    const self = this
    const $imageAnnotatorWrapper = $(e.target).closest(".image-annotator-wrapper")
    const index = $(self.element).find(".image-annotator-wrapper").index($imageAnnotatorWrapper)
    
    self.photoswipe = new PhotoSwipe(this.photoswipeTarget, PhotoSwipeUI_Default, self.items.filter(item => item != null), {
      clickToCloseNonZoomable: false,
      index,
      fullscreenEl: false,
      history: false,
      shareEl: false,
    })
    self.photoswipe.init()
  }
}