import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]

  connect() {
    $(this.element).modal("show").on("hidden.bs.modal", function() {
      $(this).next(".modal-backdrop").remove(); // This will get stuck if the user is seeing a modal when hitting the browser's back button
      $(this).remove();
    });
  }
}