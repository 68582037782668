import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [ "peekaboo" ]

  connect() {
    $(this.peekabooTarget).addClass(`d-${this.data.get("size")}-none`)
  }

  showPeekaboo() {
    $(this.peekabooTarget).removeClass(`d-${this.data.get("size")}-none`)
  }

  hidePeekaboo() {
    $(this.peekabooTarget).addClass(`d-${this.data.get("size")}-none`)
  }
}
