import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [ "input" ]

  connect() {
    if($(this.inputTarget).is(":disabled")) {
      $(this.element).addClass("disabled")
    } else {
      $(this.element).removeClass("disabled")
    }

    if($(this.inputTarget).is(":checked")) {
      $(this.element).addClass("checked")
    } else {
      $(this.element).removeClass("checked")
    }
  }

  pingUrl() {
    const self = this
    const url = self.data.get("url")

    $.ajax({
      type: "PUT",
      url: url,
      headers: {
        "X-CSRF-Token": Rails.csrfToken()
      },
      complete: () => {
        self.emit("toggle:updated", {
          name: self.inputTarget.getAttribute("name"),
          on: $(self.inputTarget).is(":checked")
        })
      }
    })
  }

  toggleChecked() {
    if($(this.inputTarget).is(":checked")) {
      $(this.element).addClass("checked")
    } else {
      $(this.element).removeClass("checked")
    }
  }
}
