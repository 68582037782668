import { Controller } from "stimulus"
import Decimal from "decimal.js-light"
import AudioPlayer from "audio_player"
import Waveform from "waveform"
import { hhMMSS } from "utils/time"

export default class extends Controller {
  static targets = [ "markerTime", "playBtnIcon", "playerTimer", "waveform", "timeStart", "zoomIn", "zoomOut" ]

  connect() {
    const duration = parseFloat(this.data.get("duration"))

    this.playerTimerTarget.innerHTML = `${hhMMSS(0, duration)} / ${hhMMSS(duration)}`

    if(this.hasMarkerTimeTarget) {
      this.markerTimeTarget.innerHTML = hhMMSS(0, duration)
    }

    if(this.hasTimeStartTarget) {
      this.timeStartTarget.value = 0
    }

    if(this.hasZoomOutTarget) {
      $(this.zoomOutTarget).addClass("disabled")
    }

    this._createWaveform()
    this.audioPlayer = new AudioPlayer({
      url: this.data.get("fileUrl"),
      keyboard: true
    })
    this.audioPlayer.on("end", this.handleEnd.bind(this))
    this.audioPlayer.on("play", this.handlePlay.bind(this))
    this.audioPlayer.on("pause", this.handlePause.bind(this))
    this.audioPlayer.on("progress", this.handleProgress.bind(this))
  }

  disconnect() {
    this.audioPlayer.destroy()
    this.waveform.destroy()
  }

  handleEnd() {
    this.handlePause()
  }

  handlePause() {
    $(this.playBtnIconTarget).removeClass("ni-button-pause").addClass("ni-button-play")

    if(this.waveform) {
      this.waveform.unfollowProgress()
    }
  }

  handlePlay() {
    $(this.playBtnIconTarget).removeClass("ni-button-play").addClass("ni-button-pause")

    if(this.waveform) {
      this.waveform.followProgress()
    }
  }

  handleProgress(e) {
    // Howler's duration rounds, so use the one from audiowaveform
    const duration = parseFloat(this.data.get("duration"))

    // For display purposes
    const roundedDuration = Math.round(duration)

    // Adjust Howler's seek by the ratio of the difference between Howler's duration and our own that we're using
    const seek = e.duration == 0 ? e.seek : new Decimal(duration).dividedBy(e.duration).times(e.seek) 

    if(this.waveform) {
      if(duration > 0) {
        this.waveform.updateProgress(seek / duration)
        this.playerTimerTarget.innerHTML = `${hhMMSS(seek, roundedDuration)} / ${hhMMSS(roundedDuration)}`

        if(this.hasMarkerTimeTarget) {
          this.markerTimeTarget.innerHTML = hhMMSS(seek, roundedDuration)
        }

        if(this.hasTimeStartTarget) {
          this.timeStartTarget.value = seek
        }
      } else {
        this.waveform.updateProgress(0)
        this.playerTimerTarget.innerHTML = `${hhMMSS(0, roundedDuration)} / ${hhMMSS(roundedDuration)}`

        if(this.hasMarkerTimeTarget) {
          this.markerTimeTarget.innerHTML = hhMMSS(0, roundedDuration)
        }

        if(this.hasTimeStartTarget) {
          this.timeStartTarget.value = 0
        }
      }
    }
  }

  get isClientSide() {
    return this.data.get("clientSide") === "true"
  }

  pause() {
    this.audioPlayer.pause()
  }

  // For adding existing markers from a different controller
  placeCommentMarker({
    id,
    addressed,
    authorInitials,
    timeEnd,
    timeStart,
  }) {
    // If the waveform has been created already, add the comments
    if(this.waveform) {
      this.waveform.addComment({
        id,
        addressed,
        authorInitials,
        timeEnd,
        timeStart,
      })
    } else { // Otherwise, queue them up to be added once it's created
      if(this._queuedComments == null) {
        this._queuedComments = []
      }

      this._queuedComments.push({
        id,
        addressed,
        authorInitials,
        timeEnd,
        timeStart,
      })
    }
  }

  removeCommentMarker(id) {
    this.waveform.removeComment(id)
  }

  seek(e) {
    this.audioPlayer.seek(e.data.posPct, false)
  }

  togglePlay() {
    if(this.audioPlayer.playing) {
      this.audioPlayer.pause()
    } else {
      this.audioPlayer.play()
    }
  }

  zoomIn() {
    this.waveform.zoomIn()
  }

  zoomOut() {
    this.waveform.zoomOut()
  }

  _createWaveform() {
    const self = this
    const dataUrl = self.data.get("waveformDataUrl")
    const duration = parseFloat(self.data.get("duration"))

    self.waveform = new Waveform({
      container: self.waveformTarget,
      dataUrl,
      duration,
      disableRangeAdjustments: !self.isClientSide
    })

    self.waveform.on("data:load", () => {
      if(self.waveform.waveformDataType == "peakarray") {
        self.zoomInTarget.remove()
        self.zoomOutTarget.remove()
      }
    })
    self.waveform.on("waveform:drawn", self._updateZoomStatus.bind(self))
    self.waveform.on("waveform:click", self.seek.bind(self))
    self.waveform.on("comment:click", self.seek.bind(self))
    self.waveform.on("comment:update", self._updateComment.bind(self))
    self.waveform.on("zoom:update", self._updateZoomStatus.bind(self))

    if(self._queuedComments) {
      self._queuedComments.forEach(comment => self.placeCommentMarker(comment))
      self._queuedComments = null
    }
    
    self.waveform.draw()
  }

  _updateComment(e) {
    const fileSystemObjectId = this.data.get("fileSystemObjectId")

    $.ajax({
      url: `/s/${publicId()}/file_system_objects/${fileSystemObjectId}/comments/${e.id}`,
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
      },
      method: "PUT",
      data: {
        comment: {
          id: e.id,
          time_start: e.timeStart,
          time_end: e.timeEnd,
        }
      },
      success: function(html) {
        const childCommentsVisible = $(`.comment-list-item-${e.id}:visible .child-comments`).hasClass("d-block")
        $(`.comment-list-item-${e.id}`).replaceWith(html)
        if(childCommentsVisible) {
          $(`.comment-list-item-${e.id}:visible .child-comments`).addClass("d-block")
          $(`.comment-list-item-${e.id}:visible .fa-comment-plus`).removeClass("fa-comment-plus").addClass("fa-comment-minus")
        }
      }
    });
  }

  _updateZoomStatus() {
    if(this.hasZoomInTarget) {
      if(this.waveform.canZoomIn) {
        $(this.zoomInTarget).removeClass("disabled")
      } else {
        $(this.zoomInTarget).addClass("disabled")
      }
    }

    if(this.hasZoomOutTarget) {
      if(this.waveform.canZoomOut) {
        $(this.zoomOutTarget).removeClass("disabled")
      } else {
        $(this.zoomOutTarget).addClass("disabled")
      }
    }
  }
}
