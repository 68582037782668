import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "authorName", "content", "timestampedComment", "timeStart" ]

  connect() {
    if(this.hasAuthorNameTarget && this.authorNameTarget.value.length == 0) {
      this.authorNameTarget.value = getItem("authorName")
    }
  }

  disableSubmitIfContentBlank(e) {
    const $form = $(this.element)

    if(this._contentExists()) {
      $form.find("[type='submit']").removeAttr("disabled")
    } else {
      $form.find("[type='submit']").attr("disabled", true)
    }
  }

  submitOnKeydown(e) {
    // Ctrl + Enter or Cmd + Enter or Windows + Enter
    if((e.ctrlKey || e.metaKey) && (e.keyCode == 13 || e.keyCode == 10) && this._contentExists()) {
      this.element.dispatchEvent(new Event("submit"))
    }
  }

  submit(e) {
    e.preventDefault();
    const $form = $(this.element)
    const self = this
    let timeStartValue

    const unsetTimeStartBeforeSerialization = this.hasTimeStartTarget && 
      this.hasTimestampedCommentTarget && 
      !this.timestampedCommentTarget.checked

    if(unsetTimeStartBeforeSerialization) {
      timeStartValue = this.timeStartTarget.value
      this.timeStartTarget.value = null
    }

    const data = $form.serialize()

    if(unsetTimeStartBeforeSerialization) {
      this.timeStartTarget.value = timeStartValue
    }

    $.ajax({
      url: $form.attr("action"),
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
      },
      method: $form.attr("method"),
      data: data,
      success: function(html) {
        const authorName = $form.find("[name='comment[author_name]']").val()
        $(".modal").modal("hide")
        $(".comment-marker[data-toggle='popover']").popover("hide")
        const commentId = $(html).attr("data-comment-id")
        const $listItems = $(`.comment-list-item-${commentId}`)

        if($listItems.length == 0) {
          const $comments = $(".comment-list-item")

          if($comments.length == 0) {
            $(".comments-container").html(html)
          } else {
            // Not the first comment to be added
            let insertBeforeClass

            // TODO: ORDERING FOR NON-AUDIO COMMENTS
            const commentTimeStartAttr = $(html).attr("data-comments--list-item-time-start")
            if(commentTimeStartAttr.length) {
              const commentTimeStart = parseFloat(commentTimeStartAttr)

              // If this comment has a timeStart, let's find where to put it
              $(".comment-list-item").each(function() {
                const timeStart = parseFloat($(this).attr("data-comments--list-item-time-start"))
                if(timeStart && timeStart >= commentTimeStart) {
                  const insertBeforeCommentId = $(this).attr("data-comment-id")
                  insertBeforeClass = `comment-list-item-${insertBeforeCommentId}`
                  return false
                }
              })

              if(insertBeforeClass) {
                $(`.${insertBeforeClass}`).before(html)
              } else {
                // If there are no timeStart comments before which to drop this, just
                // drop it at the end of the list, because the rest are general
                // comments.
                $(".comments-container").append(html)
              }
            } else {
              // If this comment doesn't have a timeStart, drop it at the top of the
              // list
              $(".comments-container").prepend(html)
            }
          }
        } else {
          $listItems.replaceWith(html)
        }

        // If this wasn't on the user's side, let's update the default authorName to be whatever they just used.
        if(authorName != null) {
          setItem("authorName", authorName)
        }
      },
      error: function(response) {
        const html = response.responseText
        let content, timestampedCommentWrapperContent

        // If this is a modal, just replace the current modal's contents to keep all
        // modal event listeners attached.
        if($(html).is("form")) {
          content = html
        } else {
          content = $(html).find("form")[0].outerHTML
        }

        if(self.hasTimestampedCommentTarget) {
          timestampedCommentWrapperContent = $(self.timestampedCommentTarget).closest(".custom-checkbox")[0].outerHTML
        }

        const id = $(content).attr("id")

        $(`#${id}`).replaceWith(content)

        if(self.hasTimestampedCommentTarget) {
          $(`#${id}`).find("[data-target*='comments--form.timestampedComment']").closest(".custom-checkbox").replaceWith(timestampedCommentWrapperContent)
        }
      },
      complete: function() {
        if(self.hasContentTarget) {
          self.contentTarget.value = null
        }
        $form.find("[type='submit']").attr("disabled", true)
        $form.find(".alert").remove()
        $form.find(".has-danger").each(function(index, element) {
          $($(element).children()[0]).unwrap()
        })
        $form.find("input, textarea").blur()
      }
    })
  }

  _contentExists() {
    return $.trim(this.contentTarget.value).length > 0 && $.trim(this.authorNameTarget.value).length > 0
  }
}
