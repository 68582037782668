import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "comment", "marker" ]

  connect() {
    // If this is an active comment list item, make its marker active
    // This is only used when a comment is toggled from unaddressed to addressed while active
    if(this.hasCommentTarget) {
      if($(this.commentTarget).hasClass("active")) {
        const commentId = this.data.get("id")
        const $marker = $(`.comment-marker-${commentId}`)
        $marker.addClass("active")
      }
    }

    // If this is a marker, make it active if its associated comment is active
    // This is only used when a marker is redrawn by the waveform class
    if(this.hasMarkerTarget) {
      const commentId = this.data.get("id")
      const $comment = $(`.comment-list-item-${commentId}:visible`)
      if($comment.hasClass("active")) {
        const $marker = $(this.markerTarget)
        $marker.addClass("active")
      }

      // Set the marker's clientSide value from its associated comment
      $marker.attr("data-comments--scroll-client-side", $comment.attr("data-comments--scroll-client-side"))
    }
  }

  get isClientSide() {
    return this.data.get("clientSide") === "true"
  }

  scrollToComment() {
    // TODO: Fix
    // if(this.isClientSide && 
    //   (!$("#comments-tab-pane").hasClass("active") || $("#client-sidebar").hasClass("hide-away"))
    // ) {
    //   $("#comments-tab")[0].click()
    // }

    const commentId = this.data.get("id")
    const $comment = $(`.comment-list-item-${commentId}:visible`)
    const $marker = $(this.markerTarget)
    $(".comment-list-item, .comment-marker").removeClass("active")
    $marker.addClass("active")
    $comment.addClass("active")

    // scrollToEl($comment)
  }

  scrollToMarker(e) {
    if(
      !$(e.target).parents(".actions").length &&
      !$(e.target).parents(".custom-checkbox").length &&
      (
        !$(e.target).attr("data-action") ||
        e.target == this.element
      )
    ) {
      const commentId = this.data.get("id")
      const $comment = $(this.commentTarget)
      const $marker = $(`.comment-marker-${commentId}`)
      const $markerContainer = $marker.closest(".image-annotator-wrapper, .waveform-wrapper")
      const commentWasAlreadyActive = $comment.hasClass("active")
      const timeStartAsPct = this.data.get("timeStartAsPct")
      $(".comment-list-item, .comment-marker").removeClass("active")

      if($marker.find(".marker-dot").length) {
        $marker.addClass("active")
        $comment.addClass("active")
        $marker.find(".marker-dot").mouseup()

        if(this.isClientSide && $markerContainer) {
          scrollToTop()
        }
      } else {
        $(".comment-marker").trigger("comment:hide")
        if(commentWasAlreadyActive) {
          $marker.trigger("comment:hide")
        } else {
          $marker.addClass("active")
          $comment.addClass("active")
          $marker.trigger("comment:show")
          
          if(this.isClientSide && $markerContainer) {
            scrollToEl($markerContainer, 20)
          }
        }
      }
      
      if(this.waveformController && timeStartAsPct.length) {
        this.waveformController.seek({ 
          data: {
            posPct: parseFloat(timeStartAsPct)
          }
        })
      }
    }
  }

  get navController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("client-nav"), 
      "client-side--nav"
    )
  } 

  get waveformController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById(`waveform-wrapper-${this.data.get("fileSystemObjectId")}`), 
      "waveform"
    )
  }
}
