import ApplicationController from "controllers/application_controller"
import { Sortable } from "@shopify/draggable"

// Must specify containerSelector and draggableSelector, and both must
// be CSS selectors

export default class extends ApplicationController {
  connect() {
    const self = this

    self.initSortable()
  }

  get containerElement() {
    const self = this
    const containerSelector = self.data.get("containerSelector")


    if(containerSelector == null) {
      return self.element
    } else {
      return document.querySelector(containerSelector)
    }
  }

  persistOrder(e) {
    const self = this
    const url = self.data.get("persistUrl")
    
    if(e.oldIndex !== e.newIndex) {
      const draggables = self.sortable.getDraggableElementsForContainer(self.containerElement)

      self.sortable.destroy()
      self.sortable = null
  
      self.containerElement.classList.add("sorting-disabled")
  
      const ids = draggables.map((draggable) => {
        return draggable.dataset.id
      })
  
      $.ajax({
        type: "POST",
        url,
        data: {
          ids,
        },
        headers: {
          "X-CSRF-Token": Rails.csrfToken()
        },
        complete: () => {
          self.initSortable()
        }
      })
    }
  }

  initSortable() {
    const self = this
    const draggableSelector = self.data.get("draggableSelector")

    self.sortable = new Sortable(self.containerElement, {
      distance: 5,
      draggable: draggableSelector,
    })
    
    // self.sortable.on('sortable:start', () => console.log('sortable:start'))
    // self.sortable.on('sortable:sort', () => console.log('sortable:sort'))
    // self.sortable.on('sortable:sorted', () => console.log('sortable:sorted'))
    self.sortable.on("sortable:stop", self.persistOrder.bind(self))

    self.containerElement.classList.remove("sorting-disabled")
  }
}