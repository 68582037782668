import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "text", "icon", "input" ]

  copyToClipboard() {
    this.executeCopyCommand()
    this.swapText()
    this.swapIcon()
    this.disable()
    const that = this
    setTimeout(function() {
      that.swapText()
      that.swapIcon()
      that.enable()
    }, 1500)
  }

  executeCopyCommand() {
    const isiOSDevice = navigator.userAgent.match(/ipad|iphone/i)
    
    if(isiOSDevice) {
      let input = this.inputTarget
      const contentEditable = input.contentEditable
      const readOnly = input.readOnly

      input.contentEditable = true
      input.readOnly = false


      var range = document.createRange()
      range.selectNodeContents(input)

      var selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)

      input.setSelectionRange(0, 999999)
      input.contentEditable = contentEditable
      input.readOnly = readOnly
    } else {
      const el = document.createElement("textarea")
      el.value = this.data.get("content")
      el.setAttribute("readonly", "")
      el.style.display = "absolute"
      el.style.left = "-9999px"
      document.body.appendChild(el)
      el.select()
      document.execCommand("copy")
      document.body.removeChild(el)
    }

    document.execCommand('copy')
  }

  disable() {
    $(this.textTarget).removeClass("btn-success").addClass("btn-default")
    $(this.textTarget).css({
      "pointer-events": "none"
    })
  }

  enable() {
    $(this.textTarget).addClass("btn-success").removeClass("btn-default")
    $(this.textTarget).css({
      "pointer-events": "initial"
    })
  }

  swapIcon() {
    if(this.hasIconTarget) {
      const originalIcon = this.iconTarget.innerHTML
      const iconToSwap = this.data.get("originalIcon")
      if(iconToSwap) {
        $(this.iconTarget).html(iconToSwap)
      } else {
        $(this.iconTarget).html(`<i class="fa fa-check"></i>`)
      }
      this.data.set("originalIcon", originalIcon)
    }
  }

  swapText() {
    if(this.hasTextTarget) {
      const originalText = this.textTarget.innerHTML
      const textToSwap = this.data.get("originalText")
      if(textToSwap) {
        $(this.textTarget).html(textToSwap)
      } else {
        $(this.textTarget).html("Copied!")
      }
      this.data.set("originalText", originalText)
    }
  }
}
