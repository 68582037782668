import ApplicationController from "controllers/application_controller"
import debounce from "lodash.debounce"
import Pickr from "@simonwep/pickr"
import "@simonwep/pickr/dist/themes/nano.min.css"

export default class extends ApplicationController {
  static targets = [ "colorPicker", "playlist", "trackName" ]

  connect() {
    const self = this

    self.reinit = debounce(self.reinit, 250, {
      leading: true,
      trailing: true
    })

    let originalColor = $("[data-progress-color]").attr("data-progress-color")
    let saved = false

    self.pickr = Pickr.create({
      el: self.colorPickerTarget,
      theme: "nano",
      comparison: true,
      components: {

        // Main components
        palette: true,
        preview: false,
        opacity: false,
        hue: true,

        // Input / output Options
        interaction: {
          hex: false,
          rgba: false,
          hsla: false,
          hsva: false,
          cmyk: false,
          input: true,
          cancel: true,
          clear: false,
          save: true,
        }
      },
      default: originalColor,
      defaultRepresentation: "HEX",
      swatches: [
        "rgba(23, 43, 77, 1)", // dark blue
        "rgba(94, 114, 228, 1)", // purple
        "rgba(17, 205, 239, 1)", // teal
        "rgba(140, 251, 208, 1)", // moving
        "rgba(255, 214, 0, 1)", // yellow
        "rgba(251, 99, 64, 1)", // orange
        "rgba(245, 54, 92, 1)", // red
      ],
      useAsButton: true,
    }).on("show", () => {
      originalColor = self.pickr.getSelectedColor().toHEXA().toString()
    }).on("change", (color) => {
      $(self.colorPickerTarget).css("background-color", color.toHEXA().toString())
    }).on("hide", () => {
      $(self.colorPickerTarget).css("background-color", originalColor)
      
      if(saved) {
        // This was triggered by the save callback
        saved = false
      } else {
        // setColor will trigger save, so tell it not to save again
        saved = true
        self.pickr.setColor(originalColor)
      }
    }).on("cancel", () => {
      self.pickr.hide()
    }).on("save", (color) => {
      if(saved) {
        // This was triggered by hide, so reset saved for the next time Pickr is opened
        saved = false
      } else {
        saved = true
        originalColor = color.toHEXA().toString()
        self.pickr.hide()

        $.ajax({
          type: "PUT",
          url: self.data.get("changeAccentColorUrl"),
          data: {
            accent_color: color.toHEXA().toString()
          },
          headers: {
            "X-CSRF-Token": Rails.csrfToken()
          },
          complete: (response) => {
            const $html = $(response.responseText)
            $(`#${$html.attr("id")}`).html($html)
            $("[data-progress-color]").attr("data-progress-color", color.toHEXA().toString())

            if(window.Playlist) {
              window.Playlist.recreateWaveform()
            }
          }
        })
      }
    })
  }

  disconnect() {
    if(window.Playlist) {
      window.Playlist.destroy()
    }
  }

  removeFormShownClass(e) {
    this.trackNameTargets.forEach(trackNameTarget => {
      if(e.srcElement.contains(trackNameTarget)) {
        trackNameTarget.classList.remove("form-shown")
      }
    })
  }

  addFormShownClass(e) {
    this.trackNameTargets.forEach(trackNameTarget => {
      if(e.srcElement.contains(trackNameTarget)) {
        trackNameTarget.classList.add("form-shown")
      }
    })
  }

  reinit() {
    if(window.Playlist) {
      window.Playlist.reinit()
    }
  }

  updateDarkMode(e) {
    if(e.detail.name == "dark_mode") {
      if(e.detail.on) {
        this.playlistTarget.classList.add("dark")
      } else {
        this.playlistTarget.classList.remove("dark")
      }
      
      if(window.Playlist) {
        window.Playlist.recreateWaveform()
      }
    }
  }
}
