import { Controller } from "stimulus"

export default class extends Controller {
  emit(eventType, eventData) {
    let event

    if (typeof CustomEvent === 'function') {
      // Modern browsers
      event = new CustomEvent(eventType, {
        bubbles: true,
        detail: eventData,
      })
    } else {
      // IE
      event = document.createEvent('CustomEvent')
      event.initCustomEvent(eventType, true, false, eventData)
    }

    this.element.dispatchEvent(event)
  }

  generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
      d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  onControllerLoad(id, controllerIdentifier, fn, counter = 0) {
    const that = this
    const controller = this.application.getControllerForElementAndIdentifier(
      document.getElementById(id), 
      controllerIdentifier
    )

    if(controller) {
      fn(controller)
    } else {
      if(counter < 5) {
        setTimeout(function() { 
          that.onControllerLoad(id, controllerIdentifier, fn, counter + 1)
        }, 100)
      } else {
        console.log(`ERROR: Controller ${controllerIdentifier} on element ${id} was not found`)
      }
    }
  }
}
