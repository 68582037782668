import { Controller } from "stimulus"
import merge from "lodash.merge"
import MultipartFileUploader from "multipart_file_uploader"
import { setupHiddenFileInput } from "utils/file"
import { showInvalidFileExtensionsModal, showMaxFileSizeModal } from "utils/modal"

export default class extends Controller {
  static targets = [ "fileBrowserButton" ]

  connect() {
    const baseUrl = this.data.get("baseUrl")

    const fileUploader = new MultipartFileUploader({
      allowedFileExtensions: [".jpg", ".jpeg", ".png"],
      baseUrl,
      maxFileSize: 5242880 // 5 MB
    })

    setupHiddenFileInput({
      addFile: fileUploader.addFile,
      allowedFileExtensions: fileUploader.allowedFileExtensions,
      containerElement: this.element,
    })

    fileUploader.on("add:error", (type, data) => { 
      if(type == "invalidFileExtensions") {
        showInvalidFileExtensionsModal(fileUploader.allowedFileExtensions, data.invalidFileExtensions) 
      } else if (type == "maxFileSize") {
        showMaxFileSizeModal("Maximum file size allowed is 5 MB.")
      }
    })
    fileUploader.on("progress", () => {
      $(this.fileBrowserButtonTarget).html(`Uploading... <i class="fa fa-spinner fa-spin"></i>`)
    })
    fileUploader.on("upload:completed", (upload) => {
      window.location.reload()
    })
  }

  openFileDialog() {
    if($(this.element).find("input[type='file']").length) {
      $(this.element).find("input[type='file']").click()
    }
  }
}