import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [ "addressedCommentsLink", "allAddressedMessage" ]

  connect() {
    this.updateState()
  }

  showAddressedCommentsLink() {
    if($(this.element).find(".comment-list-item.addressed").length) {
      $(this.addressedCommentsLinkTarget).addClass("d-inline-block").removeClass("d-none")
    } else {
      $(this.addressedCommentsLinkTarget).removeClass("d-inline-block").addClass("d-none")
    }
  }

  toggleAddressedComments() {
    if($(this.element).find(".comment-list-item.addressed.show").length) {
      $(this.element).find(".comment-list-item.addressed").removeClass("show")
    } else {
      $(this.element).find(".comment-list-item.addressed").addClass("show")
    }

    this.updateState()
  }

  setFirstAndLast() {
    $(this.element).find(".comment-list-item").removeClass("first last")
    $(this.element).find(".comment-list-item.addressed.show, .comment-list-item:not(.addressed)").first().addClass("first")
    $(this.element).find(".comment-list-item.addressed.show, .comment-list-item:not(.addressed)").last().addClass("last")
  }

  showAllAddressedMessage() {
    // If all comments are addressed and not shown, show the message
    if(this.hasAllAddressedMessageTarget) {
      if(this.allCommentsAddressed) {
        $(this.allAddressedMessageTarget).removeClass("d-none")
      } else {
        $(this.allAddressedMessageTarget).addClass("d-none")
      }
    }
  }

  updateAddressedCommentsLinkText() {
    if($(this.element).find(".comment-list-item.addressed.show").length) {
      $(this.addressedCommentsLinkTarget).text("Hide Completed Comments")
    } else {
      $(this.addressedCommentsLinkTarget).text("Show Completed Comments")
    }
  }

  updateState() {
    this.showAddressedCommentsLink()
    this.updateAddressedCommentsLinkText()
    this.setFirstAndLast()
    this.showAllAddressedMessage()
  }

  get allCommentsAddressed() {
    return $(this.element).find(".comment-list-item.addressed:not(.show)").length == $(this.element).find(".comment-list-item").length
  }
}
