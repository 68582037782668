import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [ "nameContent", "rowCheckbox" ]

  ensureExtensionSuffix(e) {
    const oldValue = e.target.value
    const start = e.target.selectionStart
    const end = e.target.selectionEnd
    const extension = this.data.get("extension")
    
    if(
      extension &&
    (
      start > (oldValue.length - extension.length) || 
      end > (oldValue.length - extension.length)
    ) && e.which !== 37 && e.which !== 39
    ) {
      e.preventDefault()
      return
    }
  }

  hideForm(e) {
    // Esc (keycode 27) or blur
    if(e.type !== "keydown" || e.which == 27) {
      $(this.nameContentTarget).html(this.data.get("nameContent"))
      this.emit("rename:form-hidden")
    }
  }

  showForm() {
    const $form = this.data.get("form")
    const nameContent = this.data.get("nameContent")
    const nameContentTarget = this.nameContentTarget
    $(nameContentTarget).html($form)
    this.emit("rename:form-shown")
    const $input = $(nameContentTarget).find("input[autofocus]")

    // Put cursor at end of input
    const val = $input.val()
    $input.val("").val(val).trigger("focus")
  }

  reinitializeWithErrors(e) {
    const [data, status, xhr] = e.detail
    $(this.nameContentTarget).html(xhr.responseText)
  }

  reinitializeWithNewName(e) {
    const [data, status, xhr] = e.detail
    const $html = $(xhr.responseText)

    if(this.hasRowCheckboxTarget && $(this.rowCheckboxTarget).is(":checked")) {
      $html.find("[data-target*='file-system-objects--rename.rowCheckbox']").prop("checked", true)
    }

    $(this.element).replaceWith($html)
  }
}
