import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [ "input", "plan" ]

  togglePeriodUnit() {
    const checked = $(this.inputTarget).is(":checked")

    $(this.planTargets).each(function() {
      if($(this).attr("data-period-unit") == "year") {
        if(checked) {
          $(this).removeClass("d-none")
        } else {
          $(this).addClass("d-none")
        }
      } else {
        if(checked) {
          $(this).addClass("d-none")
        } else {
          $(this).removeClass("d-none")
        }
      }
    })
  }
}
